import React, { useState } from 'react'
import { connect } from "react-redux"
import { SetRestaurantActivitie, DeleteRestaurantActivitie, CreateRestaurantActivitie } from '../../redux/actions'
import { getUserLocation } from '../../utils/common'
import { Row, Col, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { LocalPostsModal } from '../'

const LocalPosts = (props) => {
  const {t} = props
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [ modalData, setModalData ] = useState({
    show:false
  })

  const handleOnEditModal = (params) => {
    var objects = props.restaurantActivitie.filter((object) => { return object.localPostId === params })
    setModalData({
      show:true,
      locationId:objects[0].locationId,
      localPostId:objects[0].localPostId,
      name:objects[0].name,
      description:objects[0].description,
      dateFrom:objects[0].dateFrom,
      dateTo:objects[0].dateTo,
      hasImage:objects[0].hasImage
    })
  }

  
  const handleOnCreateLocalPost = () => {
    const userLocation = getUserLocation()
    if(userLocation)
      setModalData({
        show:true,
        mode:"create",
        locationId:userLocation,
        localPostId:null,
        name:null,
        description:null,
        dateFrom:null,
        dateTo:null,
        hasImage: false
      })
  }

  const handleOnCancelModal = () => {
    setModalData({
      show:false
    })
  }

  const handleOnChangeInputModal = (e) => {
    const { id, value } = e.target
    setModalData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnDeleteObject = () => {
    var obj = modalData
    props.DeleteRestaurantActivitie(obj)
    setModalData({
      show:false
    })
  }

  const handleOnSaveModal = () => {
    var obj = modalData
    delete (obj.show)
    if(obj.mode !== 'create')
      props.SetRestaurantActivitie(obj)
    else
      props.CreateRestaurantActivitie(obj)
    setModalData({
      show:false
    })
  }

  function dateFormatter(cell, row) {
    return (
      <span>{moment(cell).format('DD.MM.YYYY')}</span>
    )
  }

  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnEditModal(row.localPostId)
    }
  }

  const columns = [
    {
      dataField: 'name',
      text: t('localPosts-table-name'),
      sort: true
    },
    {
      dataField: 'dateFrom',
      text: t('foodMenus-table-dateFrom'),
      sort: true,
      formatter: dateFormatter
    },
    {
      dataField: 'dateTo',
      text: t('foodMenus-table-dateTo'),
      sort: true,
      formatter: dateFormatter
    }
  ]

  const defaultSorted = [{
    dataField: 'dateFrom',
    order: 'desc'
  }]

  return(
    <div className={'m-4'}>
      <Row noGutters>
        <Col className={'text-right'}>
          <Button onClick={handleOnCreateLocalPost} variant="outline-primary">{t('button-new')}</Button>
        </Col>
      </Row>
      <ToolkitProvider
        keyField="localPostId"
        data={ props.restaurantActivitie }
        defaultSorted={ defaultSorted }
        columns={ columns }
        search
      >
        {
          props => (
            <div>
              <h3>{t('foodMenus-table-sarch')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable 
                selectRow={ selectRow }
                striped
                hover
                { ...props.baseProps }
                pagination={ paginationFactory() }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
      </ToolkitProvider>
      <LocalPostsModal 
        show={modalData.show}
        data={modalData}
        handleOnCancelModal={handleOnCancelModal}
        handleOnChangeInput={handleOnChangeInputModal}
        handleOnSaveModal={handleOnSaveModal}
        handleOnRemoveModal={handleOnDeleteObject}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    restaurantActivitie: state.restaurantActivitie
  }
}

export default connect(mapStateToProps,{ SetRestaurantActivitie, DeleteRestaurantActivitie, CreateRestaurantActivitie })(withTranslation()(LocalPosts))