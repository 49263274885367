import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconEdit } from '../../assets/icons'
import moment from 'moment'


const DefaultTab = (props) => {

  const [ editDefault, setEditDefault ] = useState(false)

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('localPosts-modal-tab-headline-default')}</h4>

      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditDefault(!editDefault)}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={12} lg={12}>
        <label>{t('input-form-name')}</label>
        {editDefault ?
        <>
        <Form.Group controlId="name" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.name}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.name}</p>
        }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('foodMenus-table-dateFrom')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="dateFrom" >
            <Form.Control type={'date'} onChange={props.handleOnChange} defaultValue={moment(props.dateFrom).format('YYYY-MM-DD')}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{moment(props.dateFrom).format('DD.MM.YYYY')}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('foodMenus-table-dateTo')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="dateTo" >
            <Form.Control type={'date'} onChange={props.handleOnChange} defaultValue={moment(props.dateTo).format('YYYY-MM-DD')}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{moment(props.dateTo).format('DD.MM.YYYY')}</p>
          }
        </Col>
        <Col md={12} lg={12}>
        <label>{t('input-form-description')}</label>
        {editDefault ?
        <>
        <Form.Group controlId="description" >
          <Form.Control 
            as="textarea"
            rows={5} 
            onChange={props.handleOnChange} 
            defaultValue={props.description}
          />
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.description}</p>
        }
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(DefaultTab))