import { LOGIN_USER, LOGOUT_USER, GET_USER_LOCATIONS } from "../constants/action-types"
import { getToken, getUser}  from '../../utils/common'

export function LoginUser(guid) {
  return function(dispatch){
    return dispatch({type: LOGIN_USER, payload: guid });
  }
}
  
export function LogoutUser(guid) {
  return function(dispatch){
    return dispatch({type: LOGOUT_USER, payload: guid });
  }
}

export function GetUserLocations() {
  return function(dispatch){
    const auth = getToken()
    const user = getUser()
    if(auth && user){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users/${user.guid}/locations`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }      
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_USER_LOCATIONS, payload: json })
        }
      })
  
    }
  }
}