import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconEdit } from '../../assets/icons'

const SocialMediaTab = (props) => {

  const [ editDefault, setEditDefault ] = useState(false)

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('locations-modal-tab-headline-socialMedia')}</h4>

      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditDefault(!editDefault)}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={6} lg={6}>
          <label>{t('input-form-linkFacebook')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="linkFacebook" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.linkFacebook}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.linkFacebook}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-linkInstagram')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="linkInstagram" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.linkInstagram}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.linkInstagram}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-linkYouTube')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="linkYouTube" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.linkYouTube}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.linkYouTube}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-linkTwitter')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="linkTwitter" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.linkTwitter}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.linkTwitter}</p>
          }
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(SocialMediaTab))