import React from 'react';
import { Container, Row, Col, Form, Card, Button, Image } from 'react-bootstrap'
import ImageSection from './imageSection'
import { withTranslation } from 'react-i18next'

import './login.css'
import logo from '../../images/webdesign.png'

class CodeVerification extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      formBasicEmail: '',
      formBasicEmailError: '',
      verificationCode: '',
      verificationCodeError: ''
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.submitButton = this.submitButton.bind(this);
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  submitButton(){
    if(this.state.formBasicEmail && this.state.verificationCode){
      var request = require('request');
      var auth = new Buffer(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
      var options = {
        'method': 'POST',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users/${this.state.formBasicEmail}/reset`,
        'headers': {
          Authorization: 'Basic ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "token": this.state.verificationCode
        })
      }
      let requestBody = null
      request(options).on('data', (chunk) => {
        requestBody += chunk;
      }).on("end", () => {
        var json = JSON.parse(requestBody.substring(4))
        if (json.error) {
          const errors = {
            verificationCodeError: 'Invailid'
          }
          this.setState({
            ...this.state,
            ...errors
          })
        }
        else if (json) {
          this.props.history.push({pathname:'/resetPassword',authProps:{
            guid:json.guid,
            verificationCode:this.state.verificationCode,
            mail:this.state.formBasicEmail
          }})
        }
      })
    }
  }

  render(){
    const {t} = this.props
    return(
      <Row className={"no-gutter"} >
        <ImageSection />
        <Col md={5} lg={5}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Image fluid src={logo} alt="Webdesign by Dennis Rupp" />
                  <h3 style={{textAlign: 'center'}}>{t('login-headline-codeVerification')}</h3>
                  <h6 style={{textAlign: 'center'}}>{t('login-content-codeVerification')}</h6>
                 <Card.Body>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control 
                          type="text" 
                          placeholder={t('input-form-mail')}
                          value={this.state.formBasicEmail}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.formBasicEmailError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.formBasicEmailError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="verificationCode">
                        <Form.Control 
                          type="text" 
                          placeholder={t('input-form-code')}
                          value={this.state.verificationCode}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.verificationCodeError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.verificationCodeError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button 
                        variant="primary"
                        onClick={this.submitButton} >
                        {t('button-submit')}
                      </Button>
                    </Form>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    );
  }
}

export default (withTranslation()(CodeVerification))