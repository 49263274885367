import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconEdit } from '../../assets/icons'

const OrganisationTab = (props) => {

  const [ editDefault, setEditDefault ] = useState(false)

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('locations-modal-tab-headline-organisation')}</h4>

      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditDefault(!editDefault)}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={12} lg={12}>
          <label>{t('input-form-businessOrganisation')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="businessOrganisation" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.businessOrganisation}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.businessOrganisation}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-businessManager')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="businessManager" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.businessManager}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.businessManager}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-ustId')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="ustId" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.ustId}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.ustId}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-localCourt')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="localCourt" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.localCourt}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.localCourt}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-localCourtNumber')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="localCourtNumber" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.localCourtNumber}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.localCourtNumber}</p>
          }
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(OrganisationTab))