import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconEdit } from '../../assets/icons'

const DefaultTab = (props) => {

  const [ editDefault, setEditDefault ] = useState(false)

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('locations-modal-tab-headline-default')}</h4>

      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditDefault(!editDefault)}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={12} lg={12}>
        <label>{t('input-form-name')}</label>
        {editDefault ?
        <>
        <Form.Group controlId="name" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.name}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.name}</p>
        }
        </Col>
        <Col md={12} lg={12}>
        <label>{t('input-form-streetAddress')}</label>
        {editDefault ?
        <>
        <Form.Group controlId="streetAddress" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.streetAddress}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.streetAddress}</p>
        }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-postalCode')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="postalCode" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.postalCode}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.postalCode}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-locality')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="locality" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.locality}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.locality}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-mail')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="mail" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.mail}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.mail}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-phone')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="phone" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.phone}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.phone}</p>
          }
        </Col>
        <Col md={12} lg={12}>
          <label>{t('input-form-websiteUrl')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="websiteUrl" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.websiteUrl}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.websiteUrl}</p>
          }
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(DefaultTab))