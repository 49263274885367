import React from 'react'
import { Button, Row, Tab, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconRemove } from '../../assets/icons'
import { SetRestaurantOpeningHours } from '../../utils/requestHelper'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import moment from 'moment'

function getTimesArray(){
  const hours = []
  for(let hour = 0; hour < 24; hour++) {
    hours.push({value:moment({ hour }).format('HH:mm'), label:moment({ hour }).format('HH:mm')})
    hours.push({
        value:moment({
            hour,
            minute: 30
        }).format('HH:mm'),
        label:moment({
          hour,
          minute: 30
      }).format('HH:mm')
    })
  }
  return hours
}

const OpeningTab = (props) => {

  const {t} = props

  const dayOptions = [
    {
      value: 'SUNDAY',
      label: t('SUNDAY')
    }, 
    {
      value: 'MONDAY',
      label: t('MONDAY')
    }, 
    {
      value: 'TUESDAY',
      label: t('TUESDAY')
    }, 
    {
      value: 'WEDNESDAY',
      label: t('WEDNESDAY')
    }, 
    {
      value: 'THURSDAY',
      label: t('THURSDAY')
    },
    {
      value: 'FRIDAY',
      label: t('FRIDAY')
    },
    {
      value: 'SATURDAY',
      label: t('SATURDAY')
    }
  ]

  const timeOptions = getTimesArray()

  const columns = [{
    dataField: 'openDay',
    text: t('input-form-openDay'),
    formatter:dateFormatter,
    editor: {
      type: Type.SELECT,
      options: dayOptions
    }
  }, 
  {
    dataField: 'closeDay',
    text: t('input-form-closeDay'),
    formatter:dateFormatter,
    editor: {
      type: Type.SELECT,
      options: dayOptions
    }
  }, 
  {
    dataField: 'openTime',
    text: t('input-form-openTime'),
    editor: {
      type: Type.SELECT,
      options: timeOptions
    }
  }, 
  {
    dataField: 'closeTime',
    text: t('input-form-closeTime'),
    editor: {
      type: Type.SELECT,
      options: timeOptions
    }
  },
  {
    dataField: 'regularHourId',
    text: t('input-form-delete'),
    formatter: (cellContent, row) => {
      return (
        <IconRemove 
          onClick={()=>{props.handleOnRemoveOpeningTime(row)}}
        />
      )
    }
  }];

  function dateFormatter(cell, row) {
    return (
      <span>
        {t(`${cell}`)}
      </span>
    )
  }

  return(
    <Tab.Pane eventKey={props.eventKey}>
      <Row noGutters className={'mb-4'}>
        <Col className={'text-left'}>
          <h4>{t('locations-modal-nav-opening')}</h4>
        </Col>
        <Col className={'text-right'}>
          <Button onClick={props.handleOnCreateOpeningTime} variant="outline-primary">{t('button-new')}</Button>
        </Col>
      </Row>
      
      <BootstrapTable
        keyField="regularHourId"
        data={ props.openingTime }
        columns={ columns }
        cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: (oldValue, newValue, row, column) => { SetRestaurantOpeningHours(row) } }) }
      />
    </Tab.Pane>
  )
}

export default (withTranslation()(OpeningTab))