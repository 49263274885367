import React, { useState, useEffect } from 'react'
import { Modal, Button, Nav, Col,Tab, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { getToken } from "../../utils/common"

import DefaultTab from './defaultTab'
import SocialMediaTab from './socialMediaTab'
import OrganisationTab from './organisationTab'
import BankTab from './bankTab'
import OpeningTab from './openingTab'
import QRTab from './qrTab'

const LocationsModal = (props) => {

  const [ openingTime, setOpeningTime ] = useState([])

  useEffect(() => {
    if(props.data.show)
      getOpeningTime(props.data.locationId)
  },[props]);

  const getOpeningTime = (params) => {
    const auth = getToken()
    if(auth){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${params}/regularHours`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          setOpeningTime(json)
        }
      })
    }
  }

  const removeOpeningTime = (params) => {
    const auth = getToken()
    if(auth){
      var request = require('request');
      var options = {
        'method': 'DELETE',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${params.locationId}/regularHours/${params.regularHourId}`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = openingTime.filter(element=> element.regularHourId !== params.regularHourId)
          setOpeningTime(json)
        }
      })
    }
  }

  const cretaeOpeningTime = () => {
    const auth = getToken()
    if(auth){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${props.data.locationId}/regularHours`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          openDay:"MONDAY",
          closeDay:"MONDAY",
          openTime:"09:00",
          closeTime:"17:00"
        })
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          getOpeningTime(props.data.locationId)
        }
      })
    }
  }

  const {t} = props
  return(
    <Modal
      key={`locations-modal-${props.data.locationId}`}
      dialogClassName="modal-90w"
      show={props.show}
      onHide={props.handleOnCancelModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('locations-modal-headline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Tab.Container id="left-tabs-example" defaultActiveKey="default">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="default">{t('orders-modal-nav-overview')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="social">{t('locations-modal-nav-social')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="organisation">{t('locations-modal-nav-organisation')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bank">{t('locations-modal-nav-bank')}</Nav.Link>
                </Nav.Item>
                {props.data.mode !== 'create' && <Nav.Item>
                  <Nav.Link eventKey="opening">{t('locations-modal-nav-opening')}</Nav.Link>
                </Nav.Item>}
                <Nav.Item>
                  <Nav.Link eventKey="qrCode">{t('locations-modal-nav-qrCode')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <DefaultTab
                  eventKey="default"
                  handleOnChange={props.handleOnChangeInput}
                  streetAddress={props.data.streetAddress}
                  name={props.data.name}
                  postalCode={props.data.postalCode}
                  locality={props.data.locality}
                  phone={props.data.phone}
                  mail={props.data.mail}
                  websiteUrl={props.data.websiteUrl}
                />
                <SocialMediaTab
                  eventKey="social"
                  handleOnChange={props.handleOnChangeInput}
                  linkFacebook={props.data.linkFacebook}
                  linkInstagram={props.data.linkInstagram}
                  linkYouTube={props.data.linkYouTube}
                  linkTwitter={props.data.linkTwitter}
                />
                <OrganisationTab 
                  eventKey="organisation"
                  handleOnChange={props.handleOnChangeInput}
                  businessOrganisation={props.data.businessOrganisation}
                  businessManager={props.data.businessManager}
                  localCourt={props.data.localCourt}
                  localCourtNumber={props.data.localCourtNumber}
                  ustId={props.data.ustId}
                />
                <BankTab 
                  eventKey="bank"
                  handleOnChange={props.handleOnChangeInput}
                  paypalClientId={props.data.paypalClientId}
                  bank={props.data.bank}
                  bic={props.data.bic}
                  iban={props.data.iban}
                />
                <OpeningTab
                  eventKey="opening"
                  openingTime={openingTime}
                  handleOnRemoveOpeningTime={removeOpeningTime}
                  handleOnCreateOpeningTime={cretaeOpeningTime}
                />
                <QRTab
                  eventKey="qrCode"
                  locationId={props.data.locationId}
                  websiteUrl={props.data.websiteUrl}
                />
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        {props.data.mode !== 'create' && <Button variant="secondary" onClick={props.handleOnRemoveModal}>{t('button-delete')}</Button>}
        <Button variant="secondary" onClick={props.handleOnCancelModal}>{t('button-cancel')}</Button>
        <Button variant="primary" onClick={props.handleOnSaveModal} >{t('button-save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default (withTranslation()(LocationsModal))