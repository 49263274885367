import React, { useState } from 'react'
import { connect } from "react-redux"
import { Container } from 'react-bootstrap'
import logo from '../../images/logo-white.png'
import { Header } from '../'
import { getUser, getUserLocation } from '../../utils/common'
import { GetRestaurantStory, GetGuests, GetEmployees, GetLocations, GetRestaurantMenuData, GetNewsletters, GetContacts, GetRestaurantActivitie, GetRestaurantOrders, GetRestaurantMenuOffer } from '../../redux/actions'
import { Locations, Employees, FoodMenus, Newsletter, Contacts, LocalPosts, Orders, LocalOffers, OnlineCheckIn, LocalStorys } from '../'
import moment from 'moment'

const Dashboard = (props) => {

  const [ user, setUser ] = useState(getUser())
  const [ content, setContent ] = useState()

  const switchContent = (e) => {
    const locationId = getUserLocation()
    const id = e.target.id
    if(id==='locations'){
      props.GetLocations()
      setContent(<Locations/>)
    }
    if(id==='employees'){
      props.GetEmployees()
      props.GetLocations()
      setContent(<Employees/>)
    }
    if(id==='foodMenus'){
      props.GetRestaurantMenuData(locationId)
      setContent(<FoodMenus/>)
    }
    if(id==='newsletters'){
      props.GetNewsletters(locationId)
      setContent(<Newsletter/>)
    }
    if(id==='contacts'){
      props.GetContacts(locationId)
      setContent(<Contacts/>)
    }
    if(id==='localPosts'){
      props.GetRestaurantActivitie(locationId)
      setContent(<LocalPosts/>)
    }
    if(id==='orders'){
      props.GetRestaurantOrders(locationId)
      setContent(<Orders/>)
    }
    if(id==='localOffers'){
      props.GetRestaurantMenuOffer(locationId)
      setContent(<LocalOffers/>)
    }
    if(id==='story'){
      props.GetRestaurantStory(locationId)
      setContent(<LocalStorys/>)
    }
    if(id==='onlineCheckIn'){
      var dateFrom = moment(new Date()).format('YYYY-MM-DD')
      var dateTo= moment(new Date()).add(-31, 'd').format('YYYY-MM-DD')
      props.GetGuests(locationId,dateFrom,dateTo)
      setContent(<OnlineCheckIn/>)
    }
  }

  return(<>
    <Header
      logo={logo}
      user={user}
      onSelectNav={()=>switchContent}
    />
    <main>
      <Container fluid>
        {content}
      </Container>
    </main>
  </>)
}

const mapStateToProps = state => {
  return {
    authState: state.authState
  }
}

export default connect(mapStateToProps,{ GetRestaurantStory, GetGuests, GetEmployees, GetLocations, GetRestaurantMenuData, GetNewsletters, GetContacts, GetRestaurantActivitie, GetRestaurantOrders, GetRestaurantMenuOffer })(Dashboard)