import React, { useState, useEffect } from 'react'
import { Modal, Button, Nav, Col,Tab, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { getToken } from "../../utils/common"
import { SetRestaurantFoodMenuImage, DeleteRestaurantFoodMenuImage } from '../../utils/requestHelper'

import defaultLogo from './images/default-localposts.jpg'

import DefaultTab from './defaultTab'
import ImageTab from './imageTab'
import DisplayTab from './displayTab'

const FoodMenusModal = (props) => {

  useEffect(() => {
    if(props.data.hasImage === 1){
      renderImage()
    } else {
      setImage(defaultLogo)
    }
  },[props]);

  const [ image, setImage ] = useState(defaultLogo)

  const renderImage = () => {
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${props.data.locationId}/foodMenus/${props.data.foodMenuId}/image`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (!error){
          var json = JSON.parse(body)
          if(json.image)
            setImage(json.image)
          else
            setImage(defaultLogo)
        }
      })
    }
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      SetRestaurantFoodMenuImage({image:reader.result, locationId:props.data.locationId, foodMenuId:props.data.foodMenuId})
      setImage(reader.result)
    }
  }

  const handleOnImageDelete = () => {
    if(props.data){
      DeleteRestaurantFoodMenuImage({locationId:props.data.locationId, foodMenuId:props.data.foodMenuId})
      setImage(defaultLogo)
    }
  }

  const {t} = props
  return(
    <Modal
      key={`foodmenus-modal-${props.data.foodMenuId}`}
      dialogClassName="modal-90w"
      show={props.show}
      onHide={props.handleOnCancelModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('foodmenus-modal-headline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Tab.Container id="left-tabs-example" defaultActiveKey="default">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="default">{t('orders-modal-nav-overview')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="display">{t('foodMenus-modal-nav-display')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="image">{t('localPosts-modal-nav-image')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <DefaultTab
                  eventKey="default"
                  handleOnChange={props.handleOnChangeInput}
                  description={props.data.description}
                  name={props.data.name}
                  sku={props.data.sku}
                  price={props.data.price}
                  size={props.data.size}
                  state={props.data.state}
                />
                <DisplayTab
                  eventKey="display"
                  handleOnChange={props.handleOnChangeInput}
                  handleOnChangeSelectAllergens={props.handleOnChangeSelectAllergens}
                  handleOnChangeSelectAdditives={props.handleOnChangeSelectAdditives}
                  vegan={props.data.vegan}
                  vegetarian={props.data.vegetarian}
                  section={props.data.section}
                  category={props.data.category}
                  allergens={props.data.allergens}
                  additives={props.data.additives}
                />
                <ImageTab 
                  eventKey="image"
                  handleImageChange={handleImageChange}
                  handleOnImageDelete={handleOnImageDelete}
                  localPostsImage={image}
                />
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        {props.data.mode !== 'create' && <Button variant="secondary" onClick={props.handleOnRemoveModal}>{t('button-delete')}</Button>}
        <Button variant="secondary" onClick={props.handleOnCancelModal}>{t('button-cancel')}</Button>
        <Button variant="primary" onClick={props.handleOnSaveModal} >{t('button-save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default (withTranslation()(FoodMenusModal))