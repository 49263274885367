import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PrivateRoute from './utils/privateRoute'
import PublicRoute from './utils/publicRoute'
import withClearCache from "./ClearCache"

import { Login, SessionExpired, Loader, Dashboard, ForgottenPassword, CodeVerification, ResetPassword } from './components'

import './App.scss';

const ClearCacheComponent = withClearCache(MainApp)

function App(props) {
  return <ClearCacheComponent />
}

function MainApp(props) {
  return(
    <BrowserRouter >
      <Loader/>
      <Switch>
        <PublicRoute exact path='/' component={Login} />
        <PublicRoute path='/forgottenPassword' component={ForgottenPassword} />
        <PublicRoute path='/resetPassword' component={ResetPassword} />
        <PublicRoute path='/codeVerification' component={CodeVerification} />
        <PrivateRoute path='/dashboard' component={Dashboard} />
        <Route path='/expired' component={SessionExpired} />
      </Switch>
    </BrowserRouter>
  )
}

export default (App)