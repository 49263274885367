import React from 'react'
import { connect } from "react-redux"
import { SetGuestsCheckOut } from '../../redux/actions'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { IconRemove } from '../../assets/icons'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

const OnlineCheckIn = (props) => {
  const {t} = props
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport


  function stateFormatter(cell, row) {
    if (cell==='checkedin') {
      return (
        <span>
          <strong style={ { color: 'green' } }>{t(`onlineCheckIn-table-${cell}`)}</strong>
        </span>
      )
    }
    return (
      <span>
        <strong style={ { color: 'blue' } }>{t(`onlineCheckIn-table-${cell}`)}</strong>
      </span>
    )
  }

  function dateFormatter(cell, row) {
    if(cell)
      return (
        <span>{moment.parseZone(cell).format('DD.MM.YYYY HH:mm')}</span>
      )
  }

  function NameFormatter(cell, row) {
    return (
      <span><strong>{`${row.givenname}, ${row.surname}`}</strong><br/>{`${row.mail || ''}`}</span>
    )
  }

  const columns = [
    {
      dataField: 'tableId',
      text: t('input-form-tableId'),
      sort: true,
    },
    {
      dataField: 'givenname',
      text: t('input-form-name'),
      sort: true,
      formatter: NameFormatter
    },
    {
      dataField: 'phone',
      text: t('input-form-phone'),
    },
    {
      dataField: 'checkedInTimeStamp',
      text: t('onlineCheckIn-table-checkedInTimeStamp'),
      sort: true,
      formatter: dateFormatter
    },
    {
      dataField: 'checkedOutTimeStamp',
      text: t('onlineCheckIn-table-checkedOutTimeStamp'),
      sort: true,
      formatter: dateFormatter
    },
    {
      dataField: 'state',
      text: t('input-form-state'),
      sort: true,
      formatter: stateFormatter
    },
    {
      dataField: 'checkinId',
      text: t('input-form-delete'),
      formatter: (cellContent, row) => {
        if(row.state === 'checkedin')
          return (
            <IconRemove 
              onClick={()=>{props.SetGuestsCheckOut(row.locationId, row.checkinId)}}
            />
          )
      }
    }
  ]

  const defaultSorted = [{
    dataField: 'checkedInTimeStamp',
    order: 'desc'
  }];

  return(
    <div className={'m-4'}>
      <ToolkitProvider
          keyField="guestId"
          data={ props.restaurantGuests }
          defaultSorted={ defaultSorted }
          columns={ columns }
          search
        >
        {
          props => (
            <div>
              <h3>{t('newsletter-table-search')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable
                striped
                hover
                pagination={ paginationFactory() }
                { ...props.baseProps }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    restaurantGuests: state.restaurantGuests
  }
}

export default connect(mapStateToProps,{ SetGuestsCheckOut })(withTranslation()(OnlineCheckIn))