import React, { useRef } from 'react'
import { Image, Row, Tab, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconRemove } from '../../assets/icons'

const ImageTab = (props) => {

  const {t} = props
  const inputFile = useRef(null)

  const onButtonClick = () => {
    inputFile.current.click()
   }

  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('localPosts-modal-tab-headline-image')}</h4>
      <Row noGutters>
        <Col xs={6} md={6} className={'text-right'}>
          <Image thumbnail src={props.localPostsImage} onClick={onButtonClick} />
          <input 
            type='file' 
            id='file' 
            inputProps={{ accept: 'image/*' }} 
            ref={inputFile} 
            style={{display: 'none'}}
            onChange={props.handleImageChange}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconRemove 
            onClick={() => props.handleOnImageDelete()}
          />
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(ImageTab))