import React, { useRef, useEffect } from 'react'
import { connect } from "react-redux"
import { CreateRestaurantStory, SetRestaurantStory, DeleteRestaurantStory } from '../../redux/actions'
import { getUserLocation } from '../../utils/common'
import { Row, Col, Button, CardDeck } from 'react-bootstrap'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { withTranslation } from 'react-i18next'
import LocalStorysCard from './localStorysCard'

const LocalStorys = (props) => {
  const {t} = props
  const inputFile = useRef(null)

  const handleOnDeleteObject = (obj) => {
    props.DeleteRestaurantStory(obj)
  }

  const handleOnSave = (obj) => {
    props.SetRestaurantStory(obj)
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  const handleImageChange = (e) => {
    const userLocation = getUserLocation()
    if(userLocation) {
      e.preventDefault();
      let file = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        props.CreateRestaurantStory({image:reader.result, locationId:userLocation, title:'Upload', localStoryId:null})
      }
    }
  }

  return(
    <div className={'m-4'}>
      <Row noGutters>
        <Col className={'text-right'}>
          <Button onClick={onButtonClick} variant="outline-primary">{t('button-new')}</Button>
          <input 
            type='file' 
            id='file' 
            inputProps={{ accept: 'image/*' }} 
            ref={inputFile} 
            style={{display: 'none'}}
            onChange={handleImageChange}
          />
        </Col>
      </Row>
      <Row>
        <CardDeck>
          {props.restaurantStorys.map((slide, index) => {
            return(
              <LocalStorysCard
                item={slide}
                index={index}
                key={`gallery-card-${index}`}
                deleteCard={handleOnDeleteObject}
                handleOnSave={handleOnSave}
              />
            )
          })}
        </CardDeck>
      </Row>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    restaurantStorys: state.restaurantStorys
  }
}

export default connect(mapStateToProps,{ CreateRestaurantStory, SetRestaurantStory, DeleteRestaurantStory })(withTranslation()(LocalStorys))