import { GET_RESTAURANT_ORDERS, SET_RESTAURANT_ORDERS } from "../constants/action-types"

import { getToken } from "../../utils/common";

export function GetRestaurantOrders(locationId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/orders`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_RESTAURANT_ORDERS, payload: json });
        }
      })
    }
  }
}

export function SetRestaurantOrders(orderItem){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${orderItem.locationId}/orders/${orderItem.orderId}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(orderItem)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_RESTAURANT_ORDERS, payload: orderItem });
        }
      })
    }
  }
}