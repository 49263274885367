import React from 'react';
import { Image, Navbar,Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { removeUserSession } from '../../utils/common'
import { withTranslation } from 'react-i18next'

const Header = (props) => {
  const history = useHistory()
  const {t} = props

  const onUserLogout = () => {
    removeUserSession()
    history.push('/')
  }

  const getNavMenu = () => {
    if(props.user)
      switch (props.user.permission) {
        case 'full':
          return(
            <Nav className="mr-auto">
              <Nav.Link onClick={props.onSelectNav()} id={'locations'}>{t('nav-menu-locations')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'employees'}>{t('nav-menu-users')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'foodMenus'}>{t('nav-menu-foodMenus')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'localOffers'}>{t('nav-menu-localOffers')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'story'}>{t('nav-menu-story')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'newsletters'}>{t('nav-menu-newsletters')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'contacts'}>{t('nav-menu-contacts')}</Nav.Link>
            </Nav>
          )
        case 'write':
          return(
            <Nav className="mr-auto">
              <Nav.Link onClick={props.onSelectNav()} id={'foodMenus'}>{t('nav-menu-foodMenus')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'localOffers'}>{t('nav-menu-localOffers')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'story'}>{t('nav-menu-story')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'newsletters'}>{t('nav-menu-newsletters')}</Nav.Link>
              <Nav.Link onClick={props.onSelectNav()} id={'contacts'}>{t('nav-menu-contacts')}</Nav.Link>
            </Nav>
          )
        default:
          break;
      }
  }

  return(
    <header>
      <Navbar bg="primary" variant="dark" collapseOnSelect expand="lg" className={'fixed-top'} sticky="top" >
        <Navbar.Brand >
          <div className={'d-flex align-items-center'}>
            <a href={`${process.env.PUBLIC_URL}/`}><Image className="header-logo" src={props.logo} alt="Dennis Rupp Webdesign" /></a>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" >
            {getNavMenu()}
          <Nav>
            <Nav.Link onClick={onUserLogout}>{t('link-logout')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default (withTranslation()(Header))