import { GET_EMPLOYEES, SET_EMPLOYEES, DELETE_EMPLOYEES, CREATE_EMPLOYEES } from "../constants/action-types"

import { getToken } from "../../utils/common"

export function GetEmployees(){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_EMPLOYEES, payload: json })
        }
      })
    }
  }
}

export function SetEmployees(dataObject){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users/${dataObject.guid}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_EMPLOYEES, payload: dataObject })
        }
      })
    }
  }
}

export function CreateEmployees(dataObject){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'POST',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: CREATE_EMPLOYEES, payload: dataObject })
        }
      })
    }
  }
}

export function DeleteEmployees(dataObject){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'DELETE',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users/${dataObject.guid}`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: DELETE_EMPLOYEES, payload: dataObject })
        }
      })
    }
  }
}