import { GET_GUESTS, SET_GUESTS_CHECKOUT } from "../constants/action-types"

import { getToken } from "../../utils/common"

export function GetGuests(locationId, dateFrom, dateTo, state){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/checkin`

      if(dateFrom || dateTo || state)
        url += '?'
      if(dateFrom)
        url += `dateFrom=${dateFrom}&`
      if(dateTo)
        url += `dateTo=${dateTo}&`
      if(state)
        url += `state=${state}&`

      var request = require('request')
      var options = {
        'method': 'GET',
        'url': url,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_GUESTS, payload: json })
        }
      })
    }
  }
}

export function SetGuestsCheckOut(locationId, checkinId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/checkin/${checkinId}`

      var request = require('request')
      var options = {
        'method': 'PATCH',
        'url': url,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({state:'checkedout'})
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_GUESTS_CHECKOUT, payload: {locationId:locationId,checkinId:checkinId} })
        }
      })
    }
  }
}