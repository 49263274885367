import { LogoutUser } from "../redux/actions/index"

export const getUser = () => {
  const userStr = localStorage.getItem('drweb.user')
  if (userStr) return JSON.parse(userStr)
  else return null
}

export const verifyToken = () => {
  var token = localStorage.getItem('drweb.token')
  if(token){
    var request = require('request');
    var auth = new Buffer(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
    var options = {
      'method': 'GET',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/token?token=${token}`,
      'headers': {
        Authorization: 'Basic ' + auth,
      }
    }
    request(options, function (error, response, body) {
      if (body.indexOf('"error":true') === -1){
        setUserSession(body.token, body.user)
        return body.token;
      }
      else {
        removeUserSession()
        return null
      }
    })
  }
  else{
    return null
  }
}

export const getToken = () => {
  return localStorage.getItem('drweb.token') || null
}
 
export const removeUserSession = () => {
  localStorage.removeItem('drweb.token')
  localStorage.removeItem('drweb.user')
  localStorage.clear()
  LogoutUser()
}

export const setUserSession = (token, user) => {
  if(token && user) {
    localStorage.setItem('drweb.token', token)
    localStorage.setItem('drweb.user', JSON.stringify(user))
  }
}

export const setUserLocation = (locationCode) => {
  if(locationCode) {
    localStorage.setItem('drweb.location', locationCode)
  }
}

export const getUserLocation = () => {
  return localStorage.getItem('drweb.location') || null
}

export const killSession = () => {
  localStorage.clear()
  LogoutUser()
  window.location.href = '/expired'
}