import { GET_CONTACTS, SET_CONTACTS } from "../constants/action-types"

import { getToken } from "../../utils/common";

export function GetContacts(locationId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/contacts`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_CONTACTS, payload: json })
        }
      })
    }
  }
}

export function SetContacts(contact){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var reqObject = contact
      delete (reqObject.locationId)
      delete (reqObject.contactId)
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${contact.sd}/contacts/${contact.sm}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqObject)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_CONTACTS, payload: contact })
        }
      })
    }
  }
}