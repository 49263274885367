import { GET_NEWSLETTERS } from "../constants/action-types"

import { getToken } from "../../utils/common";

export function GetNewsletters(locationId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/newsletters`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (!error){
          var json = JSON.parse(body)
          return dispatch({ type: GET_NEWSLETTERS, payload: json })
        }
      })
    }
  }
}