import React, { useState } from 'react'
import { connect } from "react-redux"
import { SetLocation, CreateLocation, DeleteLocation } from '../../redux/actions'
import { Row, Col, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import { withTranslation } from 'react-i18next'
import LocationsModal from '../locationsModal'


const Locations = (props) => {
  const {t} = props
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [ modalData, setModalData ] = useState({
    show:false
  })

  const handleOnEditModal = (params) => {
    var objects = props.locations.filter((object) => { return object.locationId === params })
    setModalData({
      show:true,
      locationId:objects[0].locationId,
      linkFacebook:objects[0].linkFacebook,
      linkInstagram:objects[0].linkInstagram,
      linkYouTube:objects[0].linkYouTube,
      linkTwitter:objects[0].linkTwitter,
      bank:objects[0].bank,
      iban:objects[0].iban,
      bic:objects[0].bic,
      name:objects[0].name,
      phone:objects[0].phone,
      streetAddress:objects[0].streetAddress,
      postalCode:objects[0].postalCode,
      locality:objects[0].locality,
      websiteUrl:objects[0].websiteUrl,
      mail:objects[0].mail,
      businessOrganisation:objects[0].businessOrganisation,
      businessManager:objects[0].businessManager,
      localCourt:objects[0].localCourt,
      localCourtNumber:objects[0].localCourtNumber,
      ustId:objects[0].ustId,
      paypalClientId:objects[0].paypalClientId
    })
  }

  const handleOnCreateLocation = () => {
    setModalData({
      show:true,
      mode:"create",
      locationId:null,
      linkFacebook:null,
      linkInstagram:null,
      linkYouTube:null,
      linkTwitter:null,
      bank:null,
      iban:null,
      bic:null,
      name:null,
      phone:null,
      streetAddress:null,
      postalCode:null,
      locality:null,
      websiteUrl:null,
      mail:null,
      businessOrganisation:null,
      businessManager:null,
      localCourt:null,
      localCourtNumber:null,
      ustId:null,
      paypalClientId:null
    })
  }

  const handleOnCancelModal = () => {
    setModalData({
      show:false
    })
  }

  const handleOnSaveModal = () => {
    var obj = modalData
    delete (obj.show)
    if(obj.mode !== 'create')
      props.SetLocation(obj)
    else
      props.CreateLocation(obj)
    setModalData({
      show:false
    })
  }

  const handleOnChangeInputModal = (e) => {
    const { id, value } = e.target
    setModalData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnDeleteObject = () => {
    var obj = modalData
    props.DeleteLocation(obj)
    setModalData({
      show:false
    })
  }

  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnEditModal(row.locationId)
    }
  }

  const columns = [
    {
      dataField: 'name',
      text: t('input-form-name'),
      sort: true
    },
    {
      dataField: 'streetAddress',
      text: t('input-form-streetAddress'),
      sort: true
    },
    {
      dataField: 'postalCode',
      text: t('input-form-postalCode'),
      sort: true
    },
    {
      dataField: 'locality',
      text: t('input-form-locality'),
      sort: true
    },
    {
      dataField: 'websiteUrl',
      text: t('input-form-websiteUrl'),
      sort: true
    }
  ]

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }]

  return(
    <div className={'m-4'}>
      <Row noGutters>
        <Col className={'text-right'}>
          <Button onClick={handleOnCreateLocation} variant="outline-primary">{t('button-new')}</Button>
        </Col>
      </Row>
      <ToolkitProvider
        keyField="locationId"
        data={ props.locations }
        defaultSorted={ defaultSorted }
        columns={ columns }
        search
      >
        {
          props => (
            <div>
              <h3>{t('foodMenus-table-sarch')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable 
                selectRow={ selectRow }
                striped
                hover
                { ...props.baseProps }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
      </ToolkitProvider>
      <LocationsModal 
        show={modalData.show}
        data={modalData}
        handleOnCancelModal={handleOnCancelModal}
        handleOnChangeInput={handleOnChangeInputModal}
        handleOnSaveModal={handleOnSaveModal}
        handleOnRemoveModal={handleOnDeleteObject}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    locations: state.locations
  }
}

export default connect(mapStateToProps,{ SetLocation, CreateLocation, DeleteLocation })(withTranslation()(Locations))