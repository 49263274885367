import React from 'react'
import { Container} from 'react-bootstrap'
import packageJson from "../../../package.json"
import { getBuildDate } from "../../utils/buildHelper"
import { withTranslation } from 'react-i18next'

const Footer = (props) => {

  const {t} = props
  return(
    <footer className="footer fixed-bottom  mt-auto py-3">
      <div className="empty-container"></div>
      <Container>
        <div>
          <a href="mailto:dennisrupp@live.de">Webdesign by Dennis Rupp</a>
          <span>&copy; {new Date().getFullYear()}</span>
        </div>
        <div className="ml-auto">
          <span>{t('footer-release')}{getBuildDate(packageJson.buildDate)}</span>
        </div>
      </Container>
    </footer>
  );
}

export default withTranslation()(Footer);