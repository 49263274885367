import React, { useState } from 'react'
import { connect } from "react-redux"
import { SetRestaurantOrders } from '../../redux/actions'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { OrdersModal } from '../'

const Orders = (props) => {
  const {t} = props
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [ modalData, setModalData ] = useState({
    show:false
  })

  const handleOnEditModal = (params) => {
    var objects = props.restaurantOrders.filter((object) => { return object.orderId === params })
    setModalData({
      show:true,
      locationId:objects[0].locationId,
      orderId:objects[0].orderId,
      state:objects[0].state,
      totalAmount:objects[0].totalAmount,
      payment_id:objects[0].payment_id,
      payer_id:objects[0].payer_id,
      payer_mail:objects[0].payer_mail,
      payment_type:objects[0].payment_type,
      buyer_phone:objects[0].buyer_phone,
      buyer_mail:objects[0].buyer_mail,
      createdTimeStamp:objects[0].createdTimeStamp,
      billing_company:objects[0].billing_company,
      billing_givenname:objects[0].billing_givenname,
      billing_surname:objects[0].billing_surname,
      billing_streetAddress:objects[0].billing_streetAddress,
      billing_postalCode:objects[0].billing_postalCode,
      billing_location:objects[0].billing_location,
      shipping_company:objects[0].shipping_company,
      shipping_givenname:objects[0].shipping_givenname,
      shipping_surname:objects[0].shipping_surname,
      shipping_streetAddress:objects[0].shipping_streetAddress,
      shipping_postalCode:objects[0].shipping_postalCode,
      shipping_location:objects[0].shipping_location,
    })
  }

  const handleOnCancelModal = () => {
    setModalData({
      show:false
    })
  }

  const handleOnChangeInputModal = (e) => {
    const { id, value } = e.target
    setModalData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSaveModal = () => {
    var obj = modalData
    console.log(modalData)
    delete (obj.show)
    props.SetRestaurantOrders(obj)
    setModalData({
      show:false
    })
  }

  function dateFormatter(cell, row) {
    return (
      <span>{moment(cell).format('DD.MM.YYYY')}</span>
    )
  }

  function priceFormatter(cell, row) {
    return (
      <span>{parseFloat(cell).toFixed(2)}</span>
    )
  }

  function stateFormatter(cell, row) {
    if (cell==='pending') {
      return (
        <span>
          <strong style={ { color: 'blue' } }>{t(`orders-table-${cell}`)}</strong>
        </span>
      )
    }
    if (cell==='paid') {
      return (
        <span>
          <strong style={ { color: 'orange' } }>{t(`orders-table-${cell}`)}</strong>
        </span>
      )
    }
    if (cell==='shipped') {
      return (
        <span>
          <strong style={ { color: 'gray' } }>{t(`orders-table-${cell}`)}</strong>
        </span>
      )
    }
    if (cell==='completed') {
      return (
        <span>
          <strong style={ { color: 'green' } }>{t(`orders-table-${cell}`)}</strong>
        </span>
      )
    }
    return (
      <span>
        <strong>{t(`orders-table-${cell}`)}</strong>
      </span>
    )
  }

  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnEditModal(row.orderId)
    }
  }

  const columns = [
    {
      dataField: 'createdTimeStamp',
      text: t('localPosts-table-date'),
      sort: true,
      formatter: dateFormatter
    },
    {
      dataField: 'billing_givenname',
      text: t('newsletter-table-givenname'),
      sort: true
    },
    {
      dataField: 'billing_surname',
      text: t('newsletter-table-surname'),
      sort: true
      
    },
    {
      dataField: 'buyer_mail',
      text: t('newsletter-table-mail'),
      sort: true
      
    },
    {
      dataField: 'payment_type',
      text: t('orders-table-paymentType'),
      sort: true
      
    },
    {
      dataField: 'totalAmount',
      text: t('orders-table-totalAmount'),
      sort: true,
      formatter: priceFormatter
    },
    {
      dataField: 'state',
      text: t('newsletter-table-state'),
      sort: true,
      formatter: stateFormatter
      
    }
  ]

  const defaultSorted = [{
    dataField: 'createdTimeStamp',
    order: 'asc'
  }];

  return(
    <div className={'m-4'}>
      <ToolkitProvider
          keyField="orderId"
          data={ props.restaurantOrders }
          defaultSorted={ defaultSorted }
          columns={ columns }
          search
        >
        {
          props => (
            <div>
              <h3>{t('foodMenus-table-sarch')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable 
                selectRow={ selectRow }
                striped
                hover
                { ...props.baseProps }
                pagination={ paginationFactory() }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
      </ToolkitProvider>
      <OrdersModal 
        show={modalData.show}
        data={modalData}
        handleOnCancelModal={handleOnCancelModal}
        handleOnChangeInput={handleOnChangeInputModal}
        handleOnSaveModal={handleOnSaveModal}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    restaurantOrders:state.restaurantOrders
  }
}

export default connect(mapStateToProps,{ SetRestaurantOrders })(withTranslation()(Orders))