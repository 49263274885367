import React, { useState, useEffect } from 'react'
import { Modal, Button, Nav, Col,Tab, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { getToken } from "../../utils/common"
import DefaultTab from './defaultTab'
import DisplayTab from './displayTab'

const EmployeesModal = (props) => {

  useEffect(() => {
    if(props.data.show)
      getUserLocations(props.data.guid)
  },[props])

  const [ userLocations, setUserLocations ] = useState([])

  const getUserLocations = (params) => {
    const auth = getToken()
    if(auth){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users/${params}/locations`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          setUserLocations(json)
        }
      })
    }
  }

  const handleOnChangeUserLocation = (e) => {
    const { id, value } = e.target
    console.log(value)
  }

  const {t} = props
  return(
    <Modal
      key={`employees-modal-${props.data.guid}`}
      dialogClassName="modal-90w"
      show={props.show}
      onHide={props.handleOnCancelModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('user-modal-headline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Tab.Container id="left-tabs-example" defaultActiveKey="default">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="default">{t('orders-modal-nav-overview')}</Nav.Link>
                </Nav.Item>
                {props.data.mode !== 'create' && <Nav.Item>
                  <Nav.Link eventKey="permission">{t('user-modal-nav-locations')}</Nav.Link>
                </Nav.Item>}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <DefaultTab
                  eventKey="default"
                  handleOnChange={props.handleOnChangeInput}
                  mail={props.data.mail}
                  guid={props.data.guid}
                  permission={props.data.permission}
                />
                <DisplayTab
                  eventKey="permission"
                  handleOnChange={props.handleOnChangeInput}
                  handleOnChangeSelect={handleOnChangeUserLocation}
                  userLocations={userLocations}
                  locations={props.locations}
                />
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        {props.data.mode !== 'create' && <Button variant="secondary" onClick={props.handleOnRemoveModal}>{t('button-delete')}</Button>}
        <Button variant="secondary" onClick={props.handleOnCancelModal}>{t('button-cancel')}</Button>
        <Button variant="primary" onClick={props.handleOnSaveModal} >{t('button-save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default (withTranslation()(EmployeesModal))