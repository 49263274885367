import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconEdit } from '../../assets/icons'

const DefaultTab = (props) => {

  const [ editDefault, setEditDefault ] = useState(false)

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('localOffers-modal-tab-headline-default')}</h4>

      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditDefault(!editDefault)}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={12} lg={12}>
          <label>{t('input-form-guid')}</label>
          <p className="font-weight-bold" >{props.guid}</p>
        </Col>
        <Col md={6} lg={6}>
        <label>{t('input-form-mail')}</label>
        {editDefault ?
        <>
        <Form.Group controlId="mail" >
          <Form.Control type={'mail'} onChange={props.handleOnChange} defaultValue={props.mail}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.mail}</p>
        }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-permission')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="permission" >
            <Form.Control defaultValue={props.permission} onChange={props.handleOnChange} as="select" >
              <option key={'write'} value={'write'}>{t(`user-permission-write`)}</option>
              <option key={'full'} value={'full'}>{t(`user-permission-full`)}</option>
            </Form.Control>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{t(`user-permission-${props.permission}`)}</p>
          }
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(DefaultTab))