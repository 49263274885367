import { GET_RESTAURANT_MENU_DATA, CREATE_RESTAURANT_MENU_OFFER, DELETE_RESTAURANT_MENU_OFFER, GET_RESTAURANT_MENU_OFFER, GET_RESTAURANT_ACTIVITE, SET_RESTAURANT_ACTIVITE, SET_RESTAURANT_MENU_DATA, CREATE_RESTAURANT_ACTIVITE, DELETE_RESTAURANT_ACTIVITE, SET_RESTAURANT_MENU_OFFER, SET_RESTAURANT_STORY, DELETE_RESTAURANT_STORY, CREATE_RESTAURANT_STORY, GET_RESTAURANT_STORY } from "../constants/action-types"

import { getToken } from "../../utils/common";
import { RestaurantStory } from '../../utils/restaurantStoryClass'

export function GetRestaurantMenuData(locationId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/foodMenus`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_RESTAURANT_MENU_DATA, payload: json });
        }
      })
    }
  }
}

export function SetRestaurantMenuData(dataObject){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      if(dataObject.vegan)
        dataObject.vegan = 1
      else
        dataObject.vegan = 0
      if(dataObject.vegetarian)
        dataObject.vegetarian = 1
      else
        dataObject.vegetarian = 0
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${dataObject.locationId}/foodMenus/${dataObject.foodMenuId}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          category:dataObject.category,
          currency:dataObject.currency,
          description:dataObject.description,
          name:dataObject.name,
          price:dataObject.price,
          section:dataObject.section,
          size:dataObject.size,
          state:dataObject.state,
          vegan:dataObject.vegan,
          vegetarian:dataObject.vegetarian,
          additives:dataObject.additives,
          allergens:dataObject.allergens,
        })
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_RESTAURANT_MENU_DATA, payload: dataObject })
        }
      })
    }
  }
}

export function GetRestaurantMenuOffer(locationId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/localOffers`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_RESTAURANT_MENU_OFFER, payload: json });
        }
      })
    }
  }
}

export function SetRestaurantMenuOffer(localOffer){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      delete (localOffer.hasImage)
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localOffer.locationId}/localOffers/${localOffer.localOfferId}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(localOffer)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_RESTAURANT_MENU_OFFER, payload: localOffer });
        }
      })
    }
  }
}

export function DeleteRestaurantMenuOffer(localOffer){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'DELETE',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localOffer.locationId}/localOffers/${localOffer.localOfferId}`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: DELETE_RESTAURANT_MENU_OFFER, payload: localOffer });
        }
      })
    }
  }
}

export function CreateRestaurantMenuOffer(localOffer){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      delete (localOffer.hasImage)
      var options = {
        'method': 'POST',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localOffer.locationId}/localOffers`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(localOffer)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: CREATE_RESTAURANT_MENU_OFFER, payload: json });
        }
      })
    }
  }
}

export function GetRestaurantActivitie(locationId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/localPosts`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_RESTAURANT_ACTIVITE, payload: json });
        }
      })
    }
  }
}

export function SetRestaurantActivitie(localPost){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      delete (localPost.hasImage)
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localPost.locationId}/localPosts/${localPost.localPostId}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(localPost)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          localPost.hasImage = json.hasImage
          return dispatch({ type: SET_RESTAURANT_ACTIVITE, payload: localPost });
        }
      })
    }
  }
}

export function DeleteRestaurantActivitie(localPost){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'DELETE',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localPost.locationId}/localPosts/${localPost.localPostId}`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: DELETE_RESTAURANT_ACTIVITE, payload: localPost });
        }
      })
    }
  }
}

export function CreateRestaurantActivitie(localPost){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      delete (localPost.hasImage)
      var options = {
        'method': 'POST',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localPost.locationId}/localPosts`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(localPost)
      }
      
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          localPost.hasImage = json.hasImage
          localPost.localPostId = json.localPostId
          return dispatch({ type: CREATE_RESTAURANT_ACTIVITE, payload: localPost });
        }
      })
    }
  }
}

export function GetRestaurantStory(locationId){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${locationId}/localStory`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          var payload = []
          if(json.length > 1)
            json.forEach(element => {
              payload.push(RestaurantStory(element))
            })
          return dispatch({ type: GET_RESTAURANT_STORY, payload: payload });
        }
      })
    }
  }
}

export function SetRestaurantStory(localStory){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      delete (localStory.hasImage)
      var request = require('request')
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localStory.locationId}/localStory/${localStory.localStoryId}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(localStory)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_RESTAURANT_STORY, payload: localStory });
        }
      })
    }
  }
}

export function DeleteRestaurantStory(localStory){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'DELETE',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localStory.locationId}/localStory/${localStory.localStoryId}`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: DELETE_RESTAURANT_STORY, payload: localStory });
        }
      })
    }
  }
}

export function CreateRestaurantStory(localStory){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'POST',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${localStory.locationId}/localStory`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(localStory)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          localStory.localStoryId = json.localStoryId
          localStory.hasImage =1
          return dispatch({ type: CREATE_RESTAURANT_STORY, payload: RestaurantStory(localStory) })
        }
      })
    }
  }
}