import React from 'react'
import { connect } from "react-redux"
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { withTranslation } from 'react-i18next'

const Newsletter = (props) => {
  const {t} = props
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  function stateFormatter(cell, row) {
    if (cell==='subscribed') {
      return (
        <span>
          <strong style={ { color: 'green' } }>{t(`newsletter-table-${cell}`)}</strong>
        </span>
      )
    }
    return (
      <span>
        <strong style={ { color: 'blue' } }>{t(`newsletter-table-${cell}`)}</strong>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'givenname',
      text: t('newsletter-table-givenname')
    }, 
    {
      dataField: 'surname',
      text: t('newsletter-table-surname'),
      sort: true
    }, 
    {
      dataField: 'mail',
      text: t('newsletter-table-mail'),
      sort: true
    },
    {
      dataField: 'state',
      text: t('newsletter-table-state'),
      sort: true,
      formatter: stateFormatter
    }
  ]

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

  return(
    <div className={'m-4'}>
      <ToolkitProvider
          keyField="newsletterId"
          data={ props.newsletters }
          defaultSorted={ defaultSorted }
          columns={ columns }
          search
        >
        {
          props => (
            <div>
              <h3>{t('newsletter-table-search')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable
                striped
                hover
                { ...props.baseProps }
                pagination={ paginationFactory() }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    newsletters: state.newsletters
  }
}

export default connect(mapStateToProps,{  })(withTranslation()(Newsletter))