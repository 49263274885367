import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconEdit } from '../../assets/icons'

const DisplayTab = (props) => {

  const [ editDefault, setEditDefault ] = useState(false)

  const renderUserLocations = () => {
    var items = []
    props.userLocations.forEach(element => {
      items.push(<p key={element.locationId} className="font-weight-bold" >{element.name}</p>)
    })
    return items
  }

  const renderLocationOptions = () => {
    var items = []
    if(props.locations){
      props.locations.forEach(element => {
        const elementsIndex = props.userLocations.findIndex(subset => subset.locationId === element.locationId)
        if(elementsIndex !== -1)
          items.push(<option selected key={element.locationId} value={element.locationId}>{element.name}</option>)
        else
          items.push(<option key={element.locationId} value={element.locationId}>{element.name}</option>)
      });
    }
    return items
  }

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('user-modal-nav-locations')}</h4>

      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditDefault(!editDefault)}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={12} lg={12}>
        <label>{t('input-form-locations')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="locations" as={Col}>
            <Form.Control onChange={props.handleOnChangeSelect} as="select">
              {renderLocationOptions()}
            </Form.Control>
          </Form.Group>
          </> 
          :
          renderUserLocations()
          }
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(DisplayTab))