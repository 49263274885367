import React, { useState } from 'react'
import { Col, Row, Tab, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import QrCodeWithLogo from "qrcode-with-logos"
import { IconEdit } from '../../assets/icons'
import logo from './images/logo.png'
const QRTab = (props) => {

  const {t} = props
  const [ editReg, setEditReg ] = useState(false)
  const [ editMenu, setEditMenu ] = useState(false)

  const [ qrData, setQRData ] = useState({
    tableIdReg:'SW-0000',
    tableIdMenu:'SW-0000',
  })

  const handleOnChangeInput = (e) => {
    const { id, value } = e.target
    setQRData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  let qrcode = new QrCodeWithLogo({
    canvas: document.getElementById("guestregistration"),
    content: `${props.websiteUrl}/restaurantcheckin?locationId=${props.locationId}&tableId=${qrData.tableIdReg}`,
    width: 380,
    //download: true,
    //image: document.getElementById("image"),
    logo: {
      src: logo
    }
  })

  let qrcodeMenu = new QrCodeWithLogo({
    canvas: document.getElementById("menu"),
    content: `${props.websiteUrl}/restaurantmenu?locationId=${props.locationId}&tableId=${qrData.tableIdMenu}`,
    width: 380,
    //download: true,
    //image: document.getElementById("image"),
    logo: {
      src: logo
    }
  })
   
  qrcode.toCanvas().then(() => {
    qrcode.toImage().then(() => {
      setTimeout(() => {
        //qrcode.downloadImage("hello world");
      }, 2000);
    })
  })

  qrcodeMenu.toCanvas().then(() => {
    qrcodeMenu.toImage().then(() => {
      setTimeout(() => {
        //qrcode.downloadImage("hello world");
      }, 2000)
    })
  })

  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('locations-modal-tab-headline-qr')}</h4>
      <Row noGutters>
        <Col md={12} lg={12}>
          <Row noGutters>
            <Col md={8} lg={8} xs={8}>
              <p><strong>{t('locations-modal-tab-qrTab-registration')}</strong></p>
            </Col>
            <Col md={4} lg={4} xs={4} className={'text-right'}>
              <IconEdit 
                onClick={() => setEditReg(!editReg)}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col md={6} lg={6}>
              <canvas id="guestregistration"></canvas>
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-tableId')}</label>
              {editReg ?
              <>
              <Form.Group controlId="tableIdReg" >
                <Form.Control type={'text'} onChange={handleOnChangeInput} defaultValue={qrData.tableIdReg}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{qrData.tableIdReg}</p>
              }
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={12}>
          <Row noGutters>
            <Col md={8} lg={8} xs={8}>
              <p><strong>{t('locations-modal-tab-qrTab-menu')}</strong></p>
            </Col>
            <Col md={4} lg={4} xs={4} className={'text-right'}>
              <IconEdit 
                onClick={() => setEditMenu(!editMenu)}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col md={6} lg={6}>
              <canvas id="menu"></canvas>
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-tableId')}</label>
              {editMenu ?
              <>
              <Form.Group controlId="tableIdMenu" >
                <Form.Control type={'text'} onChange={handleOnChangeInput} defaultValue={qrData.tableIdMenu}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{qrData.tableIdMenu}</p>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(QRTab))