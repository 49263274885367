export const SHOW_LOADER = "SHOW_LOADER"
export const HIDE_LOADER = "HIDE_LOADER"

export const SET_RESTAURANT_ACTIVITE = "SET_RESTAURANT_ACTIVITE"
export const DELETE_RESTAURANT_ACTIVITE = "DELETE_RESTAURANT_ACTIVITE"
export const CREATE_RESTAURANT_ACTIVITE = "CREATE_RESTAURANT_ACTIVITE"
export const GET_RESTAURANT_ACTIVITE = "GET_RESTAURANT_ACTIVITE"

export const GET_RESTAURANT_MENU_DATA = "GET_RESTAURANT_MENU_DATA"
export const SET_RESTAURANT_MENU_DATA = "SET_RESTAURANT_MENU_DATA"

export const GET_RESTAURANT_MENU_OFFER = "GET_RESTAURANT_MENU_OFFER"
export const DELETE_RESTAURANT_MENU_OFFER = "DELETE_RESTAURANT_MENU_OFFER"
export const CREATE_RESTAURANT_MENU_OFFER = "CREATE_RESTAURANT_MENU_OFFER"
export const SET_RESTAURANT_MENU_OFFER = "SET_RESTAURANT_MENU_OFFER"

export const GET_LOCATIONS = "GET_LOCATIONS"
export const SET_LOCATIONS = "SET_LOCATIONS"
export const CREATE_LOCATIONS = "CREATE_LOCATIONS"
export const DELETE_LOCATIONS = "DELETE_LOCATIONS"

export const LOGIN_USER = "LOGIN_USER"
export const LOGOUT_USER = "LOGOUT_USER"
export const GET_USER_LOCATIONS = "GET_USER_LOCATIONS"

export const GET_NEWSLETTERS = "GET_NEWSLETTERS"
export const GET_CONTACTS = "GET_CONTACTS"
export const SET_CONTACTS = "SET_CONTACTS"

export const GET_RESTAURANT_ORDERS = "GET_RESTAURANT_ORDERS"
export const SET_RESTAURANT_ORDERS = "SET_RESTAURANT_ORDERS"

export const GET_EMPLOYEES = "GET_EMPLOYEES"
export const SET_EMPLOYEES = "GET_EMPLOYEES"
export const DELETE_EMPLOYEES = "DELETE_EMPLOYEES"
export const CREATE_EMPLOYEES = "CREATE_EMPLOYEES"

export const GET_GUESTS = "GET_GUESTS"
export const SET_GUESTS = "SET_GUESTS"
export const SET_GUESTS_CHECKOUT = "SET_GUESTS_CHECKOUT"

export const GET_RESTAURANT_STORY = "GET_RESTAURANT_STORY"
export const DELETE_RESTAURANT_STORY = "DELETE_RESTAURANT_STORY"
export const CREATE_RESTAURANT_STORY = "CREATE_RESTAURANT_STORY"
export const SET_RESTAURANT_STORY = "SET_RESTAURANT_STORY"