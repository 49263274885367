import React from 'react'
import { Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import BootstrapTable from 'react-bootstrap-table-next'


const ItemsTab = (props) => {
  const {t} = props

  const columns = [
    {
      dataField: 'name',
      text: t('foodMenus-table-name'),
      sort: true
    },
    {
      dataField: 'description',
      text: t('foodMenus-table-description'),
      sort: true
    },
    {
      dataField: 'qty',
      text: t('localPosts-table-qty'),
      sort: true
    },
    {
      dataField: 'amount',
      text: t('localPosts-table-amount'),
      sort: true
    }
  ]

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('orders-modal-tab-headline-items')}</h4>
      {props.orderItems && <BootstrapTable
        bootstrap4
        keyField="orderItemId"
        data={ props.orderItems }
        columns={ columns }
        defaultSorted={ defaultSorted } 
      />}
    </Tab.Pane>
  )
}

export default (withTranslation()(ItemsTab))