import React from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const ContactsModal = (props) => {

  const {t} = props
  return(
    <Modal
      key={`contacts-modal-${props.data.contactId}`}
      size="lg"
      show={props.show}
      onHide={props.handleOnCancelModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('contacts-modal-headline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row noGutters>
          <Col md={12} lg={6}>
            <label>{t('input-form-givenname')}</label>
            <p className="font-weight-bold" >{props.data.givenname}</p>
          </Col>
          <Col md={12} lg={6}>
            <label>{t('input-form-surname')}</label>
            <p className="font-weight-bold" >{props.data.surname}</p>
          </Col>
        </Row>
        <Row noGutters>
          <Col md={12} lg={12}>
            <label>{t('input-form-streetAddress')}</label>
            <p className="font-weight-bold" >{props.data.streetAddress}</p>
          </Col>
        </Row>
        <Row noGutters>
          <Col md={12} lg={6}>
            <label>{t('input-form-postalCode')}</label>
            <p className="font-weight-bold" >{props.data.postalCode}</p>
          </Col>
          <Col md={12} lg={6}>
            <label>{t('input-form-locality')}</label>
            <p className="font-weight-bold" >{props.data.location}</p>
          </Col>
        </Row>
        <Row noGutters>
          <Col md={12} lg={6}>
            <label>{t('input-form-mail')}</label>
            <p className="font-weight-bold" >{props.data.mail}</p>
          </Col>
          <Col md={12} lg={6}>
            <label>{t('input-form-phone')}</label>
            <p className="font-weight-bold" >{props.data.phone}</p>
          </Col>
        </Row>
        <Row noGutters>
          <Col md={12} lg={12}>
            <label>{t('input-form-message')}</label>
            <p className="font-weight-bold" >{props.data.message}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.handleOnCancelModal} >{t('button-close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default (withTranslation()(ContactsModal))