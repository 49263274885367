import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { IconEdit } from '../../assets/icons'
import moment from 'moment'


const DefaultTab = (props) => {

  const [ editBilling, setEditBilling ] = useState(false)
  const [ editShipping, setEditShipping ] = useState(false)
  const [ editOrderState, setEditOrderState ] = useState(false)

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('orders-modal-tab-headline-default')}</h4>

      <Row>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('orders-modal-tab-state')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditOrderState(!editOrderState)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <label>{t('input-form-state')}</label>
          {editOrderState ?
          <>
          <Form.Group controlId="state" as={Col}>
            <Form.Control defaultValue={props.state} onChange={props.handleOnChange} as="select" >
              <option key={'pending'} value={'pending'}>{t(`orders-table-pending`)}</option>
              <option key={'paid'} value={'paid'}>{t(`orders-table-paid`)}</option>
              <option key={'shipped'} value={'shipped'}>{t(`orders-table-shipped`)}</option>
              <option key={'completed'} value={'completed'}>{t(`orders-table-completed`)}</option>
              <option key={'cancel'} value={'cancel'}>{t(`orders-table-cancel`)}</option>
            </Form.Control>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{t(`orders-table-${props.state}`)}</p>
          }
        </Col>
      </Row>
      <Row noGutters>
        <Col md={12} lg={6}>
          <Col md={12} lg={12}>
            <label>{t('input-form-createdTimeStamp')}</label>
            <p className="font-weight-bold" >{moment(props.createdTimeStamp).format("DD. MM. YYYY")}</p>
          </Col>
          <Col md={12} lg={12}>
            <label>{t('input-form-updateTimeStamp')}</label>
            <p className="font-weight-bold" >{moment(props.updateTimeStamp).format("DD. MM. YYYY")}</p>
          </Col>
        </Col>
        <Col md={12} lg={6}>
          <Col md={12} lg={12}>
            <label>{t('input-form-mail')}</label>
            <p className="font-weight-bold" >{props.buyer_mail}</p>
          </Col>
          <Col md={12} lg={12}>
            <label>{t('input-form-phone')}</label>
            <p className="font-weight-bold" >{props.buyer_phone}</p>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6}>
          <Row noGutters>
            <Col md={8} lg={8} xs={8}>
              <p><strong>{t('orders-modal-tab-billingAdress')}</strong></p>
            </Col>
            <Col md={4} lg={4} xs={4} className={'text-right'}>
              <IconEdit 
                onClick={() => setEditBilling(!editBilling)}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col md={12} lg={12}>
              <label>{t('input-form-company')}</label>
              {editBilling ?
              <>
              <Form.Group controlId="billing_company" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.billing_company}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.billing_company}</p>
              }
            </Col>
            <Col md={6} lg={6}>
            <label>{t('input-form-givenname')}</label>
            {editBilling ?
            <>
            <Form.Group controlId="billing_givenname" >
              <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.billing_givenname}/>
            </Form.Group>
            </> 
            :
            <p className="font-weight-bold" >{props.billing_givenname}</p>
            }
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-surname')}</label>
              {editBilling ?
              <>
              <Form.Group controlId="billing_surname" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.billing_surname}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.billing_surname}</p>
              }
            </Col>
            <Col md={12} lg={12}>
              <label>{t('input-form-streetAddress')}</label>
              {editBilling ?
              <>
              <Form.Group controlId="billing_streetAddress" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.billing_streetAddress}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.billing_streetAddress}</p>
              }
            </Col>
            <Col md={6} lg={6}>
            <label>{t('input-form-postalCode')}</label>
            {editBilling ?
            <>
            <Form.Group controlId="billing_postalCode" >
              <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.billing_postalCode}/>
            </Form.Group>
            </> 
            :
            <p className="font-weight-bold" >{props.billing_postalCode}</p>
            }
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-locality')}</label>
              {editBilling ?
              <>
              <Form.Group controlId="billing_location" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.billing_location}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.billing_location}</p>
              }
            </Col>
          </Row>
        </Col>
        <Col md={6} lg={6}>
          <Row noGutters>
            <Col md={8} lg={8} xs={8}>
              <p><strong>{t('orders-modal-tab-shippingAdress')}</strong></p>
            </Col>
            <Col md={4} lg={4} xs={4} className={'text-right'}>
              <IconEdit 
                onClick={() => setEditShipping(!editShipping)}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col md={12} lg={12}>
            <label>{t('input-form-company')}</label>
            {editShipping ?
            <>
            <Form.Group controlId="shipping_company" >
              <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.shipping_company}/>
            </Form.Group>
            </> 
            :
            <p className="font-weight-bold" >{props.shipping_company}</p>
            }
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-givenname')}</label>
              {editShipping ?
              <>
              <Form.Group controlId="shipping_givenname" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.shipping_givenname}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.shipping_givenname}</p>
              }
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-surname')}</label>
              {editShipping ?
              <>
              <Form.Group controlId="shipping_surname" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.shipping_surname}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.shipping_surname}</p>
              }
            </Col>
            <Col md={12} lg={12}>
              <label>{t('input-form-streetAddress')}</label>
              {editShipping ?
              <>
              <Form.Group controlId="shipping_streetAddress" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.shipping_streetAddress}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.shipping_streetAddress}</p>
              }
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-postalCode')}</label>
              {editShipping ?
              <>
              <Form.Group controlId="shipping_postalCode" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.shipping_postalCode}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.shipping_postalCode}</p>
              }
            </Col>
            <Col md={6} lg={6}>
              <label>{t('input-form-locality')}</label>
              {editShipping ?
              <>
              <Form.Group controlId="shipping_location" >
                <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.shipping_location}/>
              </Form.Group>
              </> 
              :
              <p className="font-weight-bold" >{props.shipping_location}</p>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(DefaultTab))