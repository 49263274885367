import React from 'react'
import { userLogin, getUserLocations } from '../../utils/requestHelper'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image, Form, Card, Button } from 'react-bootstrap'
import ImageSection from './imageSection'
import { withTranslation } from 'react-i18next'

import './login.css'
import logo from '../../images/webdesign.png'

class Login extends React.Component{

  constructor(props){
    super(props)
    this.state = {
        formBasicEmail: '',
        formBasicEmailError: '',
        formBasicPassword: '',
        formBasicPasswordError: '',
        error: false,
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  validate = () => {
    let isError = false;
    const errors = {
      formBasicPasswordError: '',
      formBasicEmailError: ''
    }

    if(this.state.formBasicEmail.length < 1){
      isError = true;
      errors.formBasicEmailError = 'Username cannot be blank';
    }

    if(this.state.formBasicPassword.length < 1){
      isError = true;
      errors.formBasicPasswordError = 'Password cannot be blank';
    }

    this.setState({
      ...this.state,
      ...errors
    })

    return isError;
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submitLogin()
    }
  }

  submitLogin = async function(){
    const err = this.validate()
    if(!err){
      try {
        var login = await userLogin({mail: this.state.formBasicEmail, password: this.state.formBasicPassword})
        .then(parsedBody => {
          getUserLocations()
          return true})
        .catch(err => {
            console.log(err)
            return false
        })
      }
      catch (err) {
        console.error(err)
      }

      if(login)
        this.props.history.push('/dashboard')
      else {
        const errors = {
          formBasicPasswordError: 'Invailid',
          formBasicEmailError: 'Invailid'
        }
        this.setState({
          ...this.state,
          ...errors
        })
      }
    }
  }

  render(){
    const {t} = this.props
    return(
       <Row className={"no-gutter"} >
        <ImageSection />
        <Col md={5} lg={5}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Image fluid src={logo} alt="Webdesign by Dennis Rupp" />
                  <h3 style={{textAlign: 'center'}}>{t('login-headline')}</h3>
                  <h6 style={{textAlign: 'center'}}>{t('login-content')}</h6>
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>{t('input-form-mail')}</Form.Label>
                        <Form.Control 
                          type="email" 
                          placeholder={t('Mail')} 
                          value={this.state.formBasicEmail}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.formBasicEmailError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.formBasicEmailError}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('input-form-mail-inform')}!
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>{t('input-form-password')}</Form.Label>
                        <Form.Control 
                          type="password" 
                          placeholder={t('input-form-password')}
                          value={this.state.formBasicPassword}
                          onChange={this.handleOnChange}
                          onKeyDown={this.handleKeyDown}
                          isInvalid={(this.state.formBasicPasswordError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.formBasicPasswordError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button 
                        variant="primary"
                        onClick={this.submitLogin} >
                        {t('button-signin')}
                      </Button>
                    </Form>
                    <Card.Text>
                      <Link to={{
                        pathname:'/forgottenPassword'
                      }}
                      >{t('link-password-forgotten')}
                      </Link>{' '}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    )
  }
}

export default (withTranslation()(Login))