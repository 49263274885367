import React, { useState } from 'react'
import { connect } from "react-redux"
import { DeleteEmployees, SetEmployees, CreateEmployees } from '../../redux/actions'
import { Row, Col, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import { EmployeesModal } from '../'

const Employees = (props) => {
  const {t} = props

  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [ modalData, setModalData ] = useState({
    show:false
  })

  const handleOnEditModal = (params) => {
    var objects = props.employees.filter((object) => { return object.guid === params })
    setModalData({
      show:true,
      guid:objects[0].guid,
      mail:objects[0].mail,
      permission:objects[0].permission
    })
  }

  const handleOnCreateUser = () => {
    setModalData({
      show:true,
      mode:"create",
      mail:null,
      guid:null,
      permission:'write'
    })
  }

  const handleOnCancelModal = () => {
    setModalData({
      show:false
    })
  }

  const handleOnSaveModal = () => {
    var obj = modalData
    delete (obj.show)
    if(obj.mode !== 'create')
      props.SetEmployees(obj)
    else
      props.CreateEmployees(obj)
    setModalData({
      show:false
    })
  }

  const handleOnChangeInputModal = (e) => {
    const { id, value } = e.target
    setModalData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnDeleteObject = () => {
    var obj = modalData
    props.DeleteEmployees(obj)
    setModalData({
      show:false
    })
  }

  function columnFormatter(cell, row) {
    return (
      <span>{t(`user-permission-${cell}`)}</span>
    )
  }

  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnEditModal(row.guid)
    }
  }

  const columns = [
    {
      dataField: 'mail',
      text: t('input-form-mail'),
      sort: true
    },
    {
      dataField: 'permission',
      text: t('input-form-permission'),
      sort: true,
      formatter:columnFormatter
    }
  ]

  const defaultSorted = [{
    dataField: 'guid',
    order: 'desc'
  }]

  return(
    <div className={'m-4'}>
      <Row noGutters>
        <Col className={'text-right'}>
          <Button onClick={handleOnCreateUser} variant="outline-primary">{t('button-new')}</Button>
        </Col>
      </Row>
      <ToolkitProvider
        keyField="guid"
        data={ props.employees }
        defaultSorted={ defaultSorted }
        columns={ columns }
        search
      >
        {
          props => (
            <div>
              <h3>{t('foodMenus-table-sarch')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable 
                selectRow={ selectRow }
                striped
                hover
                { ...props.baseProps }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
      </ToolkitProvider>
      <EmployeesModal 
        show={modalData.show}
        data={modalData}
        locations={props.locations}
        handleOnCancelModal={handleOnCancelModal}
        handleOnChangeInput={handleOnChangeInputModal}
        handleOnSaveModal={handleOnSaveModal}
        handleOnRemoveModal={handleOnDeleteObject}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    employees: state.employees,
    locations: state.locations
  }
}

export default connect(mapStateToProps,{ DeleteEmployees, SetEmployees, CreateEmployees })(withTranslation()(Employees))