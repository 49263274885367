import { GET_LOCATIONS, SET_LOCATIONS, CREATE_LOCATIONS, DELETE_LOCATIONS } from "../constants/action-types"

import { getToken } from "../../utils/common";

export function GetLocations(){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          var json = JSON.parse(body)
          return dispatch({ type: GET_LOCATIONS, payload: json })
        }
      })
    }
  }
}

export function SetLocation(dataObject){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${dataObject.locationId}`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name:dataObject.name,
          phone:dataObject.phone,
          streetAddress:dataObject.streetAddress,
          postalCode:dataObject.postalCode,
          locality:dataObject.locality,
          websiteUrl:dataObject.websiteUrl,
          mail:dataObject.mail,
          businessOrganisation:dataObject.businessOrganisation,
          businessManager:dataObject.businessManager,
          localCourt:dataObject.localCourt,
          localCourtNumber:dataObject.localCourtNumber,
          ustId:dataObject.ustId,
          bank:dataObject.bank,
          iban:dataObject.iban,
          bic:dataObject.bic,
          linkFacebook:dataObject.linkFacebook,
          linkInstagram:dataObject.linkInstagram,
          linkYouTube:dataObject.linkYouTube,
          linkTwitter:dataObject.linkTwitter,
          paypalClientId:dataObject.paypalClientId
        })
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: SET_LOCATIONS, payload: dataObject })
        }
      })
    }
  }
}

export function CreateLocation(dataObject){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'POST',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations`,
        'headers': {
          Authorization: 'Bearer ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: CREATE_LOCATIONS, payload: dataObject })
        }
      })
    }
  }
}

export function DeleteLocation(dataObject){
  return function(dispatch){
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'DELETE',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${dataObject.locationId}`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (error)
          console.log(error)
        else{
          return dispatch({ type: DELETE_LOCATIONS, payload: dataObject })
        }
      })
    }
  }
}