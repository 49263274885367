import React from 'react'
import { connect } from "react-redux"
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "./loader.scss"

const Loading = props => {
  return(
    <>{props.showSpinner && (
      <div className="loaderCover">
        <Loader className="laoderSpinner"
          type="Plane"
          color="#002d73"
          secondaryColor="#618BD4"
          height={250}
          width={250}
        />
      </div>
    )}</>
  )
}

const mapStateToProps = state => {
  return {
    showSpinner: state.showSpinner
  }
}

export default connect(mapStateToProps)(Loading)