import { GET_RESTAURANT_MENU_DATA, CREATE_RESTAURANT_MENU_OFFER, DELETE_RESTAURANT_MENU_OFFER, SET_RESTAURANT_MENU_OFFER, GET_RESTAURANT_MENU_OFFER, GET_RESTAURANT_ACTIVITE, SET_RESTAURANT_ACTIVITE, CREATE_RESTAURANT_ACTIVITE,SET_RESTAURANT_MENU_DATA, DELETE_RESTAURANT_ACTIVITE } from "../constants/action-types";
import { LOGIN_USER, LOGOUT_USER, GET_USER_LOCATIONS  } from "../constants/action-types"
import { GET_LOCATIONS, DELETE_LOCATIONS, CREATE_LOCATIONS, SET_LOCATIONS  } from "../constants/action-types"
import { GET_NEWSLETTERS  } from "../constants/action-types"
import { GET_CONTACTS, SET_CONTACTS  } from "../constants/action-types"
import { GET_RESTAURANT_ORDERS, SET_RESTAURANT_ORDERS } from "../constants/action-types"
import { GET_EMPLOYEES, SET_EMPLOYEES, DELETE_EMPLOYEES, CREATE_EMPLOYEES } from "../constants/action-types"
import { GET_GUESTS, SET_GUESTS_CHECKOUT } from "../constants/action-types"
import { SHOW_LOADER, HIDE_LOADER } from "../constants/action-types"
import { CREATE_RESTAURANT_STORY, DELETE_RESTAURANT_STORY, GET_RESTAURANT_STORY, SET_RESTAURANT_STORY } from "../constants/action-types"

const initialState = {
  showSpinner: false,
  authState: false,
  userLocations: [],
  locations: [],
  employees: [],
  newsletters: [],
  contacts: [],
  restaurantMenuData: [],
  restaurantMenuOffer: [],
  restaurantActivitie: [],
  restaurantOrders: [],
  restaurantGuests: [],
  restaurantStorys: []
}

function rootReducer(state = initialState, action) {

  if (action.type === SHOW_LOADER) {
    return ({
      ...state,
      showSpinner: true
    })
  }

  if (action.type === HIDE_LOADER) {
    return ({
      ...state,
      showSpinner: false
    })
  }

  if (action.type === LOGIN_USER) {
    return ({
      ...state,
      authState: true
    });
  }

  if (action.type === LOGOUT_USER) {
    return ({
      ...state,
      authState: false
    });
  }

  if (action.type === GET_USER_LOCATIONS) {
    return ({
      ...state,
      userLocations: action.payload,
    });
  }

  if (action.type === GET_EMPLOYEES) {
    return ({
      ...state,
      employees: action.payload
    });
  }
  
  if (action.type === GET_NEWSLETTERS) {
    return ({
      ...state,
      newsletters: action.payload,
    });
  }

  if (action.type === GET_CONTACTS) {
    return ({
      ...state,
      contacts: action.payload
    });
  }

  if (action.type === DELETE_EMPLOYEES) {
    return ({
      ...state,
      employees:state.employees.filter(element=> element.guid !== action.payload.guid),
    })
  }

  if (action.type === CREATE_EMPLOYEES) {
    var newArray = [...state.employees]
    newArray.push(action.payload)
    return ({
      ...state,
      employees:newArray
    })
  }

  if (action.type === SET_EMPLOYEES) {
    const elementsIndex = state.employees.findIndex(element => element.guid === action.payload.guid)
    var oldEmployee = state.employees.find(element => element.guid === action.payload.guid)

    if(oldEmployee)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldEmployee[key] = action.payload[key]
    })

    let newArray = [...state.employees]
    if(oldEmployee)
      newArray[elementsIndex] = oldEmployee
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      employees:newArray,
    })
  }

  if (action.type === SET_CONTACTS) {
    const elementsIndex = state.contacts.findIndex(element => element.contactId === action.payload.sm)
    var oldContact = state.contacts.find(element => element.contactId === action.payload.sm)

    if(oldContact)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldContact[key] = action.payload[key]
    })

    let newArray = [...state.contacts]
    if(oldContact)
      newArray[elementsIndex] = oldContact
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      contacts:newArray,
    })
  }

  if (action.type === SET_RESTAURANT_ORDERS) {
    const elementsIndex = state.restaurantOrders.findIndex(element => element.orderId === action.payload.orderId)
    var oldOrderItem = state.restaurantOrders.find(element => element.orderId === action.payload.orderId)

    if(oldOrderItem)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldOrderItem[key] = action.payload[key]
    })

    let newArray = [...state.restaurantOrders]
    if(oldOrderItem)
      newArray[elementsIndex] = oldOrderItem
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      restaurantOrders:newArray,
    })
  }

  if (action.type === GET_LOCATIONS) {
    return ({
      ...state,
      locations: action.payload
    });
  }

  if (action.type === SET_LOCATIONS) {
    const elementsIndex = state.locations.findIndex(element => element.locationId === action.payload.locationId)
    var oldLocation = state.locations.find(element => element.locationId === action.payload.locationId)

    if(oldLocation)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldLocation[key] = action.payload[key]
    })

    let newArray = [...state.locations]
    if(oldLocation)
      newArray[elementsIndex] = oldLocation
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      locations:newArray,
    })
  }

  if (action.type === SET_RESTAURANT_MENU_DATA) {
    const elementsIndex = state.restaurantMenuData.findIndex(element => element.foodMenuId === action.payload.foodMenuId)
    var oldRestaurantMenu = state.locations.find(element => element.foodMenuId === action.payload.foodMenuId)

    if(oldRestaurantMenu)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldRestaurantMenu[key] = action.payload[key]
    })

    let newArray = [...state.restaurantMenuData]
    if(oldRestaurantMenu)
      newArray[elementsIndex] = oldRestaurantMenu
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      restaurantMenuData:newArray,
    })
  }

  if (action.type === SET_RESTAURANT_MENU_OFFER) {
    const elementsIndex = state.restaurantMenuOffer.findIndex(element => element.localOfferId === action.payload.localOfferId)
    var oldRestaurantMenuOffer = state.restaurantMenuOffer.find(element => element.localOfferId === action.payload.localOfferId)

    if(oldRestaurantMenuOffer)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldRestaurantMenuOffer[key] = action.payload[key]
    })

    let newArray = [...state.restaurantMenuOffer]
    if(oldRestaurantMenuOffer)
      newArray[elementsIndex] = oldRestaurantMenuOffer
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      restaurantMenuOffer:newArray,
    })
  }
  
  if (action.type === SET_RESTAURANT_STORY) {
    const elementsIndex = state.restaurantStorys.findIndex(element => element.localStoryId === action.payload.localStoryId)
    var oldRestaurantStorys = state.restaurantStorys.find(element => element.localStoryId === action.payload.localStoryId)

    if(oldRestaurantStorys)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldRestaurantStorys[key] = action.payload[key]
    })

    let newArray = [...state.restaurantStorys]
    if(oldRestaurantStorys)
      newArray[elementsIndex] = oldRestaurantStorys
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      restaurantStorys:newArray,
    })
  }

  if (action.type === SET_RESTAURANT_ACTIVITE) {
    const elementsIndex = state.restaurantActivitie.findIndex(element => element.localPostId === action.payload.localPostId)
    var oldlocalPost = state.contacts.find(element => element.localPostId === action.payload.localPostId)

    if(oldlocalPost)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldlocalPost[key] = action.payload[key]
    })

    let newArray = [...state.restaurantActivitie]
    if(oldlocalPost)
      newArray[elementsIndex] = oldlocalPost
    else
      newArray[elementsIndex] = action.payload
    return ({
      ...state,
      restaurantActivitie:newArray,
    })
  }

  if (action.type === GET_RESTAURANT_MENU_DATA) {
    return ({
      ...state,
      restaurantMenuData: action.payload
    })
  }

  if (action.type === GET_RESTAURANT_MENU_OFFER) {
    return ({
      ...state,
      restaurantMenuOffer: action.payload
    })
  }

  if (action.type === GET_RESTAURANT_ACTIVITE) {
    return ({
      ...state,
      restaurantActivitie: action.payload
    })
  }

  if (action.type === DELETE_RESTAURANT_ACTIVITE) {
    return ({
      ...state,
      restaurantActivitie:state.restaurantActivitie.filter(element=> element.localPostId !== action.payload.localPostId),
    })
  }
  
  if (action.type === DELETE_RESTAURANT_STORY) {
    return ({
      ...state,
      restaurantStorys:state.restaurantStorys.filter(element=> element.localStoryId !== action.payload.localStoryId),
    })
  }

  if (action.type === DELETE_RESTAURANT_MENU_OFFER) {
    return ({
      ...state,
      restaurantMenuOffer:state.restaurantMenuOffer.filter(element=> element.localOfferId !== action.payload.localOfferId),
    })
  }

  if (action.type === DELETE_LOCATIONS) {
    return ({
      ...state,
      locations:state.locations.filter(element=> element.locationId !== action.payload.locationId),
    })
  }

  if (action.type === CREATE_LOCATIONS) {
    var locations = [...state.locations]
    locations.push(action.payload)
    return ({
      ...state,
      locations:locations
    })
  }

  if (action.type === CREATE_RESTAURANT_ACTIVITE) {
    var restaurantActivitie = [...state.restaurantActivitie]
    restaurantActivitie.push(action.payload)
    return ({
      ...state,
      restaurantActivitie: restaurantActivitie
    })
  }

  if (action.type === CREATE_RESTAURANT_MENU_OFFER) {
    var restaurantMenuOffer = [...state.restaurantMenuOffer]
    restaurantMenuOffer.push(action.payload)
    return ({
      ...state,
      restaurantMenuOffer: restaurantMenuOffer
    })
  }
  
  if (action.type === CREATE_RESTAURANT_STORY) {
    var restaurantStorys = [...state.restaurantStorys]
    restaurantStorys.push(action.payload)
    return ({
      ...state,
      restaurantStorys: restaurantStorys
    })
  }

  if (action.type === GET_RESTAURANT_ORDERS) {
    return ({
      ...state,
      restaurantOrders: action.payload
    })
  }

  if (action.type === GET_RESTAURANT_STORY) {
    return ({
      ...state,
      restaurantStorys: action.payload
    })
  }

  if (action.type === GET_GUESTS) {
    return ({
      ...state,
      restaurantGuests: action.payload
    })
  }

  if (action.type === SET_GUESTS_CHECKOUT) {
    var changeObjects = state.restaurantGuests.filter(element=> element.checkinId === action.payload.checkinId)
    var newArray = state.restaurantGuests.filter(element=> element.checkinId !== action.payload.checkinId)

    changeObjects.forEach(element => {
      element.state = 'checkedout'
      element.checkedOutTimeStamp = new Date()

      newArray.push(element)
    })

    return ({
      ...state,
      restaurantGuests: newArray
    })
  }

  return state
}

export default rootReducer