import React, { useState } from 'react'
import { Form, Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { IconEdit } from '../../assets/icons'

import additives from '../../config/Additives.json'
import allergens from '../../config/Allergens.json'

const DisplayTab = (props) => {

  const [ editDefault, setEditDefault ] = useState(false)

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('localOffers-modal-tab-headline-display')}</h4>

      <Row noGutters>
        <Col md={8} lg={8} xs={8}>
          <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => setEditDefault(!editDefault)}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={6} lg={6}>
        <label>{t('input-form-section')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="section" as={Col}>
            <Form.Control defaultValue={props.section} onChange={props.handleOnChange} as="select" >
              <option key={'breakfast'} value={'breakfast'}>{t(`menu-breakfast`)}</option>
              <option key={'sunday'} value={'sunday'}>{t(`menu-sunday`)}</option>
              <option key={'appetizer'} value={'appetizer'}>{t(`menu-appetizer`)}</option>
              <option key={'lunch'} value={'lunch'}>{t(`menu-lunch`)}</option>
              <option key={'desert'} value={'desert'}>{t(`menu-desert`)}</option>
              <option key={'beverages'} value={'beverages'}>{t(`menu-beverages`)}</option>
            </Form.Control>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{t(`menu-${props.section}`)}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-category')}</label>
          {editDefault ?
          <>
          <Form.Group controlId="category" >
            <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.category}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.category}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-vegetarian')}</label>
          {editDefault ?
          <>
          <fieldset>
            <Form.Group onChange={props.handleOnChange}>
              <Col>
              <Form.Check 
                type="switch"
                id="vegetarian"
                label={t('input-form-vegetarian')}
                defaultChecked={props.vegetarian}
                />
              </Col>
            </Form.Group>
          </fieldset>
          </> 
          :
          <p className="font-weight-bold" >{props.vegetarian === 1 ? t('yes') : t('no')}</p>
          }     
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-vegan')}</label>
          {editDefault ?
          <>
          <fieldset>
            <Form.Group onChange={props.handleOnChange}>
              <Col>
              <Form.Check 
                type="switch"
                id="vegan"
                label={t('input-form-vegan')}
                defaultChecked={props.vegan}
                />
              </Col>
            </Form.Group>
          </fieldset>
          </> 
          :
          <p className="font-weight-bold" >{props.vegan === 1 ? t('yes') : t('no')}</p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-allergens')}</label>
          {editDefault ?
          <>
          <fieldset>
            <Form.Group>
              <Col>
              <Select
                defaultValue={props.allergens}
                isMulti
                name="colors"
                options={allergens}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={props.handleOnChangeSelectAllergens}
              />
              </Col>
            </Form.Group>
          </fieldset>
          </> 
          :
          <p className="font-weight-bold" >{props.allergens && (props.allergens.split(';')).map((item)=>{
            return(
              <>{t(`${item}`)} </>
            )
          })}
          </p>
          }
        </Col>
        <Col md={6} lg={6}>
          <label>{t('input-form-additives')}</label>
          {editDefault ?
          <>
          <fieldset>
            <Form.Group>
              <Col>
              <Select
                defaultValue={props.additives}
                isMulti
                name="colors"
                options={additives}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={props.handleOnChangeSelectAdditives}
              />
              </Col>
            </Form.Group>
          </fieldset>
          </> 
          :
          <p className="font-weight-bold" >{props.additives && (props.additives.split(';')).map((item)=>{
            return(
              <>{t(`${item}`)} </>
            )
          })}
          </p>
          }
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(DisplayTab))