import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { getToken } from "../../utils/common"
import { IconEdit, IconRemove } from '../../assets/icons'

import defaultLogo from './images/default-localposts.jpg'

const LocalStorysCard = (props) => {
  const {t} = props
  const [ image, setImage ] = useState(defaultLogo)
  const [ edit, setEdit ] = useState(false)
  const [ data, setData ] = useState({locationId:props.item.locationId, localStoryId:props.item.localStoryId, title:props.item.title})

  useEffect(() => {
    if(props.item.hasImage === 1){
      renderImage()
    }
  },[props])

  const handleOnChangeInput = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnChangeEdit = () => {
    if(data.title !== props.item.title && edit)
      props.handleOnSave(data)
    setEdit(!edit)
  }

  const renderImage = () => {
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${props.item.locationId}/localStory/${props.item.localStoryId}/image`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (!error){
          var json = JSON.parse(body)
          if(json.image)
            setImage(json.image)
          else
            setImage(defaultLogo)
        }
      })
    }
  }

  return(
    <Card>
      <Card.Header>
        <IconRemove 
          onClick={()=>props.deleteCard(props.item)}
        />
      </Card.Header>
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Row noGutters>
          <Col md={8} lg={8} xs={8}>
            <p><strong>{t('localPosts-modal-tab-edit')}</strong></p>
          </Col>
          <Col md={4} lg={4} xs={4} className={'text-right'}>
            <IconEdit 
              onClick={() => handleOnChangeEdit()}
            />
          </Col>
        </Row>
        {edit ?
        <>
        <Form.Group controlId="title" >
          <Form.Control type={'text'} onChange={handleOnChangeInput} defaultValue={props.item.title}/>
        </Form.Group>
        </> 
        :
        <Card.Title>{props.item.title}</Card.Title>
        }
      </Card.Body>
    </Card>
  )
}

export default (withTranslation()(LocalStorysCard))