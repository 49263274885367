import React, { useState, useEffect } from 'react'
import { Modal, Button, Col,Nav, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { getToken } from "../../utils/common"
import DefaultTab from './defaultTab'
import ItemsTab from './itemsTab'
import PaymentTab from './paymentTab'


const OrdersModal = (props) => {

  useEffect(() => {
    if(props.data.orderId)
      loadOrderItems()
  },[props.data.orderId]);

  const {t} = props
  const [ orderItems, setOrderItems ] = useState([])

  const loadOrderItems = () => {
    const auth = getToken()
    if(auth){
      var request = require('request')
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${props.data.locationId}/orders/${props.data.orderId}/orderItems`,
        'headers': {
          Authorization: 'Bearer ' + auth
        }
      }
      request(options, function (error, response, body) {
        if (!error){
          var json = JSON.parse(body)
          setOrderItems(json)
        }
      })
    }
  }

  return(
    <Modal
      key={`orders-modal-${props.data.orderId}`}
      dialogClassName="modal-90w"
      show={props.show}
      onHide={props.handleOnCancelModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('orders-modal-headline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="default">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="default">{t('orders-modal-nav-overview')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="items">{t('orders-modal-nav-items')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="payment">{t('orders-modal-nav-payment')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <DefaultTab
                  eventKey="default"
                  handleOnChange={props.handleOnChangeInput}
                  locationId={props.data.locationId}
                  orderId={props.data.orderId}
                  state={props.data.state}
                  buyer_phone={props.data.buyer_phone}
                  buyer_mail={props.data.buyer_mail}
                  createdTimeStamp={props.data.createdTimeStamp}
                  billing_company={props.data.billing_company}
                  billing_givenname={props.data.billing_givenname}
                  billing_surname={props.data.billing_surname}
                  billing_streetAddress={props.data.billing_streetAddress}
                  billing_postalCode={props.data.billing_postalCode}
                  billing_location={props.data.billing_location}
                  shipping_company={props.data.shipping_company}
                  shipping_givenname={props.data.shipping_givenname}
                  shipping_surname={props.data.shipping_surname}
                  shipping_streetAddress={props.data.shipping_streetAddress}
                  shipping_postalCode={props.data.shipping_postalCode}
                  shipping_location={props.data.shipping_location}
                />
                <ItemsTab 
                  eventKey="items"
                  orderItems={orderItems}
                />
                <PaymentTab 
                  eventKey="payment"
                  totalAmount={props.data.totalAmount}
                  payment_id={props.data.payment_id}
                  payment_type={props.data.payment_type}
                  payer_mail={props.data.payer_mail}
                  payer_id={props.data.payer_id}
                />
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleOnCancelModal}>{t('button-cancel')}</Button>
        <Button variant="primary" onClick={props.handleOnSaveModal} >{t('button-save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default (withTranslation()(OrdersModal))