import React from 'react';
import { Container, Row, Col, Form, Card, Button, Image } from 'react-bootstrap'
import ImageSection from './imageSection'
import { withTranslation } from 'react-i18next'

import './login.css'
import logo from '../../images/webdesign.png'

class ResetPassword extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      password: null,
      passwordConfirmation: null,
      passwordError: '',
      verificationCode: typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.verificationCode : null,
      guid: typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.guid : null,
      mail: typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.mail : null,
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.submitButton = this.submitButton.bind(this);
  }

  validate = () => {
    let isError = false;
    const errors = {
      passwordError: '',
      error: false
    }

    if(this.state.password !== this.state.passwordConfirmation){
      isError = true;
      errors.passwordError = 'Die Passwörter stimmen nicht überein!';
    }

    this.setState({
      ...this.state,
      ...errors
    })

    return isError;
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  submitButton(){
    const err = this.validate()
    if(this.state.guid && this.state.verificationCode && !err){
      var request = require('request');
      var auth = new Buffer(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
      var options = {
        'method': 'PATCH',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users/${this.state.mail}/reset`,
        'headers': {
          Authorization: 'Basic ' + auth,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "token": this.state.verificationCode,
          "guid": this.state.guid,
          "password": this.state.password
        })
      }
      let requestBody = null
      request(options).on('data', (chunk) => {
        requestBody += chunk;
      }).on("end", () => {
        this.props.history.push('/')
      })
    }
  }

  render(){
    const {t} = this.props
    return(
      <Row className={"no-gutter"} >
        <ImageSection />
        <Col md={5} lg={5}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Image fluid src={logo} alt="Webdesign by Dennis Rupp" />
                  <h3 style={{textAlign: 'center'}}>{t('login-headline-resetPassword')}</h3>
                  <h6 style={{textAlign: 'center'}}>{t('login-content-resetPassword')}</h6>
                 <Card.Body>
                    <Form>
                      <Form.Group controlId="password">
                        <Form.Control 
                          type="password"
                          placeholder={t('input-form-password')}
                          value={this.state.password}
                          onChange={this.handleOnChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="passwordConfirmation">
                        <Form.Control 
                          type="password"
                          placeholder={t('input-form-password')} 
                          value={this.state.passwordConfirmation}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.passwordError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.passwordError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button 
                        variant="primary"
                        onClick={this.submitButton} >
                        {t('button-submit')}
                      </Button>
                    </Form>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    );
  }
}

export default (withTranslation()(ResetPassword))