import settings from '../config/Settings.json'

import { setUserSession, getToken, getUser, setUserLocation } from './common'
import { LogoutUser, LoginUser } from "../redux/actions/index"

export const userLogin = (params) => {
  var request = require('request');
  var auth = new Buffer(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
  var options = {
    'method': 'POST',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/accounts`,
    'headers': {
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "mail": params.mail,
      "password": params.password
    })
  }
  return new Promise((resolve, reject) => {
      request(options, function (response, body) {
        let res = JSON.parse(body.body)
        if (res.error){
          LogoutUser()
          reject(res)
        }
        else{
          LoginUser()
          setUserSession(res.token, res.user)
          resolve(res.user)
        }
    })
  })
}

export const getUserLocations = () => {
  const auth = getToken()
  const user = getUser()
  if(auth && user){
    var request = require('request');
    var options = {
      'method': 'GET',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/users/${user.guid}/locations`,
      'headers': {
        Authorization: 'Bearer ' + auth
      }
    }
    return new Promise((resolve, reject) => {
        request(options, function (response, body) {
          let res = JSON.parse(body.body)
          if (res.error){

            reject(res)
          }
          else{
            if(res[0])
            setUserLocation(res[0].locationId)
            resolve(res)
          }
      })
    })
  }
}

export const unsubscribeNewsletter = (newsletterId) => {
  var request = require('request');
  var auth = new Buffer(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
  var options = {
    'method': 'DELETE',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${settings.primaryLocationCode}/newsletters/${newsletterId}`,
    'headers': {
      Authorization: 'Basic ' + auth,
    }
  }
  request(options, function (error, response, body) {
    if (!error)
      return true
    else
      return false
  })
}

export function SetRestaurantActivitieImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'PATCH',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/localPosts/${data.localPostId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        image:data.image
      })
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}

export function DeleteRestaurantActivitieImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'DELETE',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/localPosts/${data.localPostId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth
      }
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}

export function SetRestaurantMenuOfferImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'PATCH',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/localOffers/${data.localOfferId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        image:data.image
      })
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}

export function DeleteRestaurantMenuOfferImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'DELETE',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/localOffers/${data.localOfferId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth
      }
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}

export function SetRestaurantOpeningHours(data){
  const auth = getToken()
  if(auth){
    var request = require('request');
    var options = {
      'method': 'PATCH',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/regularHours/${data.regularHourId}`,
      'headers': {
        Authorization: 'Bearer ' + auth
      },
      body: JSON.stringify(data)
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
      else{
        var json = JSON.parse(body)
      }
    })
  }
}

export function SetRestaurantFoodMenuImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'PATCH',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/foodMenus/${data.foodMenuId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        image:data.image
      })
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}

export function DeleteRestaurantFoodMenuImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'DELETE',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/foodMenus/${data.foodMenuId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth
      }
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}

export function SetRestaurantLocalStoryImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'PATCH',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/localStory/${data.localStoryId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        image:data.image
      })
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}

export function DeleteRestaurantLocalStoryImage(data){
  const auth = getToken()
  if(auth){
    var request = require('request')
    var options = {
      'method': 'DELETE',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${data.locationId}/localStory/${data.localStoryId}/image`,
      'headers': {
        Authorization: 'Bearer ' + auth
      }
    }
    request(options, function (error, response, body) {
      if (error)
        console.log(error)
    })
  }
}