import React, { useState } from 'react'
import { connect } from "react-redux"
import { SetRestaurantMenuData } from '../../redux/actions'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { withTranslation } from 'react-i18next'
import FoodMenusModal from '../foodMenusModal'

const FoodMenus = (props) => {
  const {t} = props
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [ foodMenu, setFoodMenusModal ] = useState({
    show:false
  })

  const handleOnEditFoodMenus = (params) => {
    var objects = props.restaurantMenuData.filter((object) => { return object.foodMenuId === params })
    setFoodMenusModal({
      show:true,
      locationId:objects[0].locationId,
      foodMenuId:objects[0].foodMenuId,
      category:objects[0].category,
      currency:objects[0].currency,
      description:objects[0].description,
      name:objects[0].name,
      price:objects[0].price,
      section:objects[0].section,
      size:objects[0].size,
      sku:objects[0].sku,
      state:objects[0].state,
      vegan:objects[0].vegan,
      vegetarian:objects[0].vegetarian,
      hasImage:objects[0].hasImage,
      additives:objects[0].additives,
      allergens:objects[0].allergens,
    })
  }

  const handleOnCacelFoodMenus = () => {
    setFoodMenusModal({
      show:false
    })
  }

  const handleOnChangeInputFoodMenus = (e) => {
    var { id, value } = e.target
    if(id==='vegan')
      value = !foodMenu.vegan
    if(id==='vegetarian')
      value = !foodMenu.vegetarian
    
    setFoodMenusModal(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnChangeSelectAllergens = (e) => {
    var result = e.map(a => a.value);
    setFoodMenusModal(prevData => ({
      ...prevData,
      allergens: result.join(';')
    }))
  }

  const handleOnChangeSelectAdditives = (e) => {
    var result = e.map(a => a.value);
    setFoodMenusModal(prevData => ({
      ...prevData,
      additives: result.join(";")
    }))
  }

  const handleOnSaveFoodMenus = () => {
    var obj = foodMenu
    delete (obj.show)
    props.SetRestaurantMenuData(obj)
    setFoodMenusModal({
      show:false
    })
  }


  function stateFormatter(cell, row) {
    if (cell==='active') {
      return (
        <span>
          <strong style={ { color: 'green' } }>{t(`foodMenus-table-${cell}`)}</strong>
        </span>
      )
    }
    return (
      <span>
        <strong style={ { color: 'red' } }>{t(`foodMenus-table-${cell}`)}</strong>
      </span>
    )
  }

  function menuFormatter(cell, row) {
    return (
      <span>
        {t(`menu-${cell}`)}
      </span>
    )
  }

  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnEditFoodMenus(row.foodMenuId)
    }
  }

  const columns = [
    {
      dataField: 'sku',
      text: t('foodMenus-table-sku')
    }, 
    {
      dataField: 'name',
      text: t('foodMenus-table-name'),
      sort: true
    }, 
    {
      dataField: 'price',
      text: t('foodMenus-table-price'),
      sort: true
    },
    {
      dataField: 'section',
      text: t('foodMenus-table-section'),
      sort: true,
      formatter: menuFormatter
    },
    {
      dataField: 'state',
      text: t('foodMenus-table-state'),
      sort: true,
      formatter: stateFormatter
    }
  ]

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

  return(
    <div className={'m-4'}>
      <ToolkitProvider
          keyField="foodMenuId"
          data={ props.restaurantMenuData }
          defaultSorted={ defaultSorted }
          columns={ columns }
          search
        >
        {
          props => (
            <div>
              <h3>{t('foodMenus-table-sarch')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable 
                selectRow={ selectRow }
                striped
                hover
                { ...props.baseProps }
                pagination={ paginationFactory() }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
        </ToolkitProvider>
        <FoodMenusModal 
          show={foodMenu.show}
          data={foodMenu}
          handleOnCancelModal={handleOnCacelFoodMenus}
          handleOnChangeInput={handleOnChangeInputFoodMenus}
          handleOnChangeSelectAllergens={handleOnChangeSelectAllergens}
          handleOnChangeSelectAdditives={handleOnChangeSelectAdditives}
          handleOnSaveModal={handleOnSaveFoodMenus}
        />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    restaurantMenuData: state.restaurantMenuData
  }
}

export default connect(mapStateToProps,{ SetRestaurantMenuData })(withTranslation()(FoodMenus))