import React from 'react'
import { Row, Col, Image} from 'react-bootstrap'

import './login.css'
import customer from '../../images/logo-white.png'

const ImageSection = (props) => {

  const style = {
    center : {
      "textAlign" : "center"
    }
  }
  return(
    <div className={"d-none d-md-flex col-md-7 col-lg-7 bg-image"}>
      <Row className={'h-100'}>
        <Col className={'my-auto'} style={style.center}>
          <Image style={{width: "60%"}} src={customer} alt="Schlemmerwerk im Merkle" />
        </Col>
      </Row>
    </div>
  )
}

export default ImageSection