import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken, verifyToken } from './common';

function PrivateRoute({ component: Component, user, ...rest }) {

  useEffect( () => {
    const token = getToken();
    if (!token) {
      return;
    }
    verifyToken()
  })

  return (
    <Route
      {...rest}
      render={(props) => getToken() ? <Component {...props} {...user} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}
 
export default PrivateRoute;