import React from 'react'
import { Col, Row, Tab } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const PaymentTab = (props) => {

  const {t} = props
  return(
    <Tab.Pane eventKey={props.eventKey}>
      <h4>{t('orders-modal-tab-headline-payment')}</h4>
      <Row noGutters>
        <Col md={12} lg={6}>
          <Col md={12} lg={12}>
            <label>{t('input-form-payment_type')}</label>
            <p className="font-weight-bold" >{props.payment_type}</p>
          </Col>
          <Col md={12} lg={12}>
            <label>{t('input-form-payment_id')}</label>
            <p className="font-weight-bold" >{props.payment_id}</p>
          </Col>
        </Col>
        <Col md={12} lg={6}>
          <Col md={12} lg={12}>
            <label>{t('input-form-mail')}</label>
            <p className="font-weight-bold" >{props.payer_mail}</p>
          </Col>
          <Col md={12} lg={12}>
            <label>{t('input-form-payer_id')}</label>
            <p className="font-weight-bold" >{props.payer_id}</p>
          </Col>
        </Col>
      </Row>
    </Tab.Pane>
  )
}

export default (withTranslation()(PaymentTab))