import React from 'react'
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const SessionExpired = (props) => {
  const history = useHistory()
  const back = () => {
    history.push('/')
  }

  const {t} = props
  return(
    <>
    <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
      <div className={"d-none d-md-flex col-md-7 col-lg-7 bg-image"}></div>
      <Col md={5} lg={5}>
        <div className={"login d-flex align-items-center py-5"}>
          <Container>
            <Row>
              <Col className={"mx-auto"} md={9} lg={8} >
                <Col md={12} lg={12} xs={12} className={'text-center'}>
                  <h1>{t('session-expired-headline')}</h1>
                  <h4>{t('session-expired-content')}</h4>
                </Col>
                <Col md={12} lg={12} xs={12} className={'text-center'}>
                  <Button 
                    variant="primary"
                    onClick={()=>back()} >
                    {t('button-back-to-home')}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
    </>
  )
  
}

export default withTranslation()(SessionExpired);