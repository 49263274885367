import React, { useState } from 'react'
import { connect } from "react-redux"
import { SetContacts } from '../../redux/actions'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { withTranslation } from 'react-i18next'
import ContactsModal from './contactsModal'
import moment from 'moment'

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'

const Contacts = (props) => {
  const {t} = props
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [ modalData, setModalData ] = useState({
    show:false
  })

  const handleOnEdit = (params) => {
    var objects = props.contacts.filter((object) => { return object.contactId === params })
    setModalData({
      show:true,
      contactId:objects[0].contactId,
      locationId:objects[0].locationId,
      givenname:objects[0].givenname,
      surname:objects[0].surname,
      streetAddress:objects[0].streetAddress,
      postalCode:objects[0].postalCode,
      location:objects[0].location,
      message:objects[0].message,
      mail:objects[0].mail,
      phone:objects[0].phone,
      state:objects[0].state,
      createdTimeStamp:objects[0].createdTimeStamp,
      sm:objects[0].contactId,
      sd:objects[0].locationId
    })
  }

  const handleOnCancelModal = () => {
    if(modalData.state === 'new'){
      var obj = modalData
      delete (obj.show)
      obj.state = "read"
      props.SetContacts(obj)
    }
    setModalData({
      show:false
    })
  }

  function stateFormatter(cell, row) {
    if (cell==='new') {
      return (
        <span>
          <strong style={ { color: 'green' } }>{t(`contact-table-${cell}`)}</strong>
        </span>
      )
    }
    return (
      <span>
        <strong>{t(`contact-table-${cell}`)}</strong>
      </span>
    )
  }

  function dateFormatter(cell, row) {
    return (
      <span>{moment(cell).format('DD.MM.YYYY')}</span>
    )
  }

  const columns = [
    {
      dataField: 'createdTimeStamp',
      text: t('newsletter-table-date'),
      formatter: dateFormatter
    }, 
    {
      dataField: 'givenname',
      text: t('newsletter-table-givenname')
    }, 
    {
      dataField: 'surname',
      text: t('newsletter-table-surname'),
      sort: true
    }, 
    {
      dataField: 'mail',
      text: t('newsletter-table-mail'),
      sort: true
    },
    {
      dataField: 'state',
      text: t('newsletter-table-state'),
      sort: true,
      formatter: stateFormatter
    }
  ]

  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnEdit(row.contactId)
    }
  }

  const defaultSorted = [{
    dataField: 'createdTimeStamp',
    order: 'desc'
  }]

  return(
    <div className={'m-4'}>
      <ToolkitProvider
          keyField="contactId"
          data={ props.contacts }
          defaultSorted={ defaultSorted }
          columns={ columns }
          search
        >
        {
          props => (
            <div>
              <h3>{t('newsletter-table-search')}</h3>
              <SearchBar { ...props.searchProps } />
              <ClearSearchButton { ...props.searchProps } />
              <hr />
              <BootstrapTable 
                selectRow={ selectRow }
                striped
                hover
                { ...props.baseProps }
                pagination={ paginationFactory() }
              />
              <ExportCSVButton { ...props.csvProps }>{t('button-export-csv')}</ExportCSVButton>
            </div>
          )
        }
      </ToolkitProvider>
      <ContactsModal
        show={modalData.show}
        data={modalData}
        handleOnCancelModal={handleOnCancelModal}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    contacts: state.contacts
  }
}

export default connect(mapStateToProps,{ SetContacts })(withTranslation()(Contacts))